import {
  Badge,
  Body_Text_16,
  HPageTitle20,
  TextButton,
  palette,
} from 'modules/defines/styles';
import styled, { css } from 'styled-components';

interface RefundInfoProps {
  category: string;
  noTitle?: boolean;
  marginTop?: string;
  fontStyle: 'Badge' | 'BodyText16';
}

const RefundInfo: React.FC<RefundInfoProps> = ({
  category,
  noTitle = false,
  marginTop,
  fontStyle,
}) => {
  return (
    <Container marginTop={marginTop}>
      {!noTitle && <Title>환불 안내</Title>}
      {(category === '푸드' || category === '굿즈') && (
        <RefundInfoWrapper>
          <RefundInfoRow>
            <RefundInfoDateText>구매후 30일 까지</RefundInfoDateText>
            <RefundInfoValueText>100% 환불</RefundInfoValueText>
          </RefundInfoRow>
          <RefundInfoRow>
            <RefundInfoDateText>구매후 30일 이후</RefundInfoDateText>
            <RefundInfoValueText>90% 환불</RefundInfoValueText>
          </RefundInfoRow>
        </RefundInfoWrapper>
      )}
      {/* FIXME 관람일 (티켓 사용 일자)이 정해져 있는 경우 처리 해야함 */}
      {category === '전시' && (
        <>
          <RefundInfoWrapper>
            <RefundInfoRow>
              <RefundInfoDateText>구매후 7일 까지</RefundInfoDateText>
              <RefundInfoValueText width="80px">100% 환불</RefundInfoValueText>
            </RefundInfoRow>
            <RefundInfoRow>
              <RefundInfoDateText>
                구매후 7일 ~ 전시/공연 끝나는 날짜
              </RefundInfoDateText>
              <RefundInfoValueText width="80px">90% 환불</RefundInfoValueText>
            </RefundInfoRow>
            <RefundInfoRow>
              <RefundInfoDateText>
                전시/공연 끝나는 날짜 이후
              </RefundInfoDateText>
              <RefundInfoValueText width="80px">환불 불가</RefundInfoValueText>
            </RefundInfoRow>
          </RefundInfoWrapper>
        </>
      )}
      <RefundPolicyRow>
        <RefundPolicyText fontStyle={fontStyle}>
          상품에 따라 환불 약관이 다르게 적용됩니다.
        </RefundPolicyText>
        <RefundPolicyText fontStyle={fontStyle}>
          환불 희망 시, {'['}구매 내역 {'>'} 환불 요청{']'}을 눌러주세요.
        </RefundPolicyText>
        <RefundPolicyText fontStyle={fontStyle}>
          환불 금액은 영업일 기준 3~7일 이내에 지급됩니다.
        </RefundPolicyText>
        <RefundPolicyText fontStyle={fontStyle}>
          환불 규정은 주말 및 공휴일을 포함하여 산정 되며, 결제 당일 취소 신청을
          하는 경우에도 동일하게 규정이 적용됩니다.
        </RefundPolicyText>
        <RefundPolicyText fontStyle={fontStyle}>
          미성년자가 법정 대리인의 동의 없이 구매한 경우, 미성년자 또는 법정
          대리인이 구매를 취소할 수 있습니다.
        </RefundPolicyText>
      </RefundPolicyRow>
    </Container>
  );
};
const Container = styled.div<{ marginTop?: string }>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
`;

const Title = styled.div`
  ${HPageTitle20};
  color: #1e1e1e;
  border-bottom: 1px solid #717171;
  box-sizing: border-box;
  padding-bottom: 1rem;
  margin-bottom: 1.25rem;
`;
const RefundInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${palette.neutral003};
  border-radius: 6px;
  margin-bottom: 0.94rem;
`;

const RefundInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  border-bottom: 1px solid ${palette.neutral003};
  &:last-child {
    border-bottom: none;
  }
`;

const RefundInfoDateText = styled.div`
  ${TextButton};
  color: ${palette.neutral006};
  text-align: left;
`;

const RefundInfoValueText = styled.div<{ width?: string }>`
  color: ${palette.neutral006};
  ${TextButton};

  text-align: center;
  border-left: 1px solid ${palette.neutral003};
  padding-left: 20px;
  width: ${(props) => (props.width ? props.width : '80px')};
`;

const RefundPolicyRow = styled.div`
  margin-bottom: 0.5rem;
  color: #333;
`;
const RefundPolicyText = styled.li<{ fontStyle: 'Badge' | 'BodyText16' }>`
  list-style-type: disc;
  color: ${palette.neutral006};
  padding: 0 0.1875rem;
  ${(props) => props.fontStyle === 'Badge' && Badge}
  ${(props) =>
    props.fontStyle === 'BodyText16' &&
    css`
      ${Body_Text_16}
      font-weight: 400;
    `};
  ${(props) =>
    props.fontStyle === 'Badge' &&
    css`
      margin-left: 1.3125rem;
      text-indent: -1.125rem;
    `}
  ${(props) =>
    props.fontStyle === 'BodyText16' &&
    css`
      margin-left: 1.3125rem;
      text-indent: -1.4rem;
    `};
`;

export default RefundInfo;
