import styled, { keyframes } from 'styled-components';
import {
  BottomSheetContainer,
  BottomSheetWrapper,
} from 'components/ProductDetail/BottomSheetCommon';
import { Button, palette } from 'modules/defines/styles';
import { PropsWithChildren, useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { spinner } from 'assets/lottie';
interface BottomSheetProps {
  children: PropsWithChildren<React.ReactNode>;
  buttonType: 'reset' | 'submit' | 'button';
  onClick: () => void;
  disabled?: boolean;
  ButtonContent: string;
  hide: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
}
const BottomSheet: React.FC<BottomSheetProps> = ({
  children,
  buttonType,
  onClick,
  disabled,
  ButtonContent,
  hide,
  isLoading,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <BottomSheetContainer
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          hide(false);
        }
      }}
    >
      <BottomSheetWrapper>
        {children}
        <ButtonSheetButton
          type={buttonType}
          onClick={onClick}
          disabled={disabled}
        >
          {isLoading && (
            <LoadingContainer>
              <Player
                style={{
                  width: '20px',
                  height: '20px',
                }}
                id="spinner_player"
                src={spinner}
                autoplay
                loop
              />
            </LoadingContainer>
          )}
          {ButtonContent}
        </ButtonSheetButton>
      </BottomSheetWrapper>
    </BottomSheetContainer>
  );
};

const ButtonSheetButton = styled.button`
  ${Button};
  color: white;
  margin: 0 auto 2.125rem auto;
  width: calc(100% - 50px);
  border-radius: 10px;
  padding: 1.25rem 0;
  background: ${palette.neutral007};
  &:active {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.3) 100%
      ),
      #1e1e1e;
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;
export default BottomSheet;
