import styled from 'styled-components';
import { palette, Body_Text_16 } from 'modules/defines/styles';
import { getOptionFinalPrice, printCleanDiscount } from 'utils/util';
import { addCommaToNum } from 'modules/functions/utils';

const ProductOptionList = ({ product }) => {
  return (
    <OptionWrapper>
      <SubTitleWhite>상품 안내</SubTitleWhite>
      {product.options.length === 0 ? (
        <OptionCell>
          <OptionTitleRow>
            <OptionLabel>옵션1</OptionLabel>
            <OptionTitle>{product.title}</OptionTitle>
          </OptionTitleRow>
          <OptionPriceRow>
            <OptionPriceDiscountRate>
              {printCleanDiscount(product.discount)}%
            </OptionPriceDiscountRate>
            <OptionPrice>
              <span style={{ marginRight: '1px' }}>
                {addCommaToNum(
                  getOptionFinalPrice(product.discount, product.price, 0, 1)
                )}
              </span>
              원
            </OptionPrice>
          </OptionPriceRow>
        </OptionCell>
      ) : (
        product.options.map((option, idx) => {
          return (
            <OptionCell>
              <OptionTitleRow>
                <OptionLabel>옵션{idx + 1}</OptionLabel>
                <OptionTitle>{option.title}</OptionTitle>
              </OptionTitleRow>
              <OptionPriceRow>
                <OptionPriceDiscountRate>
                  {printCleanDiscount(product.discount)}%
                </OptionPriceDiscountRate>
                <OptionPrice>
                  <span style={{ marginRight: '1px' }}>
                    {addCommaToNum(
                      getOptionFinalPrice(
                        product.discount,
                        product.price,
                        option.price,
                        1
                      )
                    )}
                  </span>
                  원
                </OptionPrice>
              </OptionPriceRow>
            </OptionCell>
          );
        })
      )}
    </OptionWrapper>
  );
};

const OptionWrapper = styled.div`
  background-color: #010101;
  padding: 40px 30px 60px 30px;
`;

const SubTitleWhite = styled.div`
  color: ${palette.white};
  ${Body_Text_16};
  margin-bottom: 30px;
`;

const OptionCell = styled.div`
  height: 140px;
  background-color: white;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const OptionTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;
const OptionTitle = styled.div`
  color: ${palette.neutral006};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
  vertical-align: middle;
`;
const OptionLabel = styled.div`
  background-color: #000;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  padding: 4px 10px;
  margin-right: 15px;
`;

const OptionPriceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding-right: 20px;
  margin-bottom: 16px;
`;
const OptionPriceDiscountRate = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: #0097ff;
`;
const OptionPrice = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
  color: #0097ff;
  margin-left: 3px;
  color: ${palette.fontBlack};
`;

export default ProductOptionList;
