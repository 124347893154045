import ArrowDown from 'assets/icons/ArrowDown';
import ArrowUp from 'assets/icons/ArrowUp';
import { encodeToBase64 } from 'modules/defines/encrypt';
import { PurchaseItem } from 'modules/defines/interfaces';
import { Button, TextButton, palette } from 'modules/defines/styles';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import styled, { css } from 'styled-components';

interface Option {
  idx: number;
  price: number;
  title: string;
}

interface QRData {
  isTotal: boolean; // 일괄 사용인지, 단일 사용인지, 일괄 사용이면 true
  spaceTitle: string;
  orderName: string;
  orderId: string; // DB
  orderIdNumber: string;
  expireAt: string;
  option: Option;
  orderNumber: string;
}

interface TicketItemProps {
  idx: number;
  sTitle: string;
  orderItem: PurchaseItem;
  count?: number; // 사용 가능 잔여 개수
}

const TicketItem: React.FC<TicketItemProps> = ({
  idx,
  sTitle,
  orderItem,
  count = 0,
}) => {
  const [toggleOpen, setToggleOpen] = useState(false);

  const qrData: QRData = {
    isTotal: idx === -1,
    spaceTitle: sTitle,
    orderName: orderItem.orderName,
    orderIdNumber: orderItem.orderIdNumber,
    orderId: orderItem.orderId,
    expireAt: orderItem.expireAt,
    option: orderItem.option,
    orderNumber: orderItem.orderNumber,
  };

  return (
    <Container
      onClick={() =>
        ((idx === -1 && count !== 0) ||
          (idx !== -1 && orderItem.usedAt === null)) &&
        setToggleOpen(!toggleOpen)
      }
      disabled={
        (idx === -1 && count === 0) || (idx !== -1 && orderItem.usedAt !== null)
      }
    >
      <HeaderWrapper>
        <TitleWrapper>
          {idx === -1 ? (
            <Title disabled={idx === -1 && count === 0}>
              한 번에 확인{' '}
              <TitleDesc disabled={idx === -1 && count === 0}>
                (사용가능 잔여: {count}개)
              </TitleDesc>
            </Title>
          ) : (
            <>
              {orderItem.option !== null ? (
                <TitleOptionWrapper>
                  <Title disabled={orderItem.usedAt !== null}>
                    옵션{idx + 1}
                  </Title>
                  <TitleDivider />
                  <Title disabled={orderItem.usedAt !== null}>
                    {orderItem.option && orderItem.option.title}
                  </Title>
                </TitleOptionWrapper>
              ) : (
                <TitleOptionWrapper>
                  <Title disabled={orderItem.usedAt !== null}>{idx + 1}</Title>
                  <TitleDivider />
                  <Title disabled={orderItem.usedAt !== null}>{sTitle}</Title>
                </TitleOptionWrapper>
              )}
            </>
          )}

          {toggleOpen ? (
            <ArrowUp
              disabled={
                (idx === -1 && count === 0) ||
                (idx !== -1 && orderItem.usedAt !== null)
              }
            />
          ) : (
            <ArrowDown
              disabled={
                (idx === -1 && count === 0) ||
                (idx !== -1 && orderItem.usedAt !== null)
              }
            />
          )}
        </TitleWrapper>
        {idx !== -1 && (
          <OrderNum disabled={orderItem.usedAt !== null}>
            상품번호 {orderItem.orderNumber}
          </OrderNum>
        )}
      </HeaderWrapper>
      {toggleOpen && (
        <QRCodeContainer>
          <QRCodeWrapper>
            <QRCode
              style={{ width: '100%', height: '100%' }}
              value={`https://manager.favapp.io/use-approval?orderInfo=${encodeToBase64(JSON.stringify(qrData))}`}
            />
          </QRCodeWrapper>
        </QRCodeContainer>
      )}
    </Container>
  );
};
const Container = styled.div<{ disabled?: boolean }>`
  border-radius: 8px;
  background: ${palette.neutral002};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;
      &:hover {
        background: ${palette.neutral001};
      }
    `}
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const TitleOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const Title = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: 8px;
  color: ${palette.neutral007};
  ${Button};

  ${(props) =>
    props.disabled &&
    css`
      color: ${palette.neutral004};
    `}
`;
const TitleDesc = styled.div<{ disabled?: boolean }>`
  color: ${palette.neutral007};
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem; /* 117.647% */

  ${(props) =>
    props.disabled &&
    css`
      color: ${palette.neutral004};
    `}
`;
const OrderNum = styled.div<{ disabled?: boolean }>`
  color: ${palette.neutral005};
  ${TextButton};

  ${(props) =>
    props.disabled &&
    css`
      color: ${palette.neutral004};
    `}
`;

const TitleDivider = styled.div`
  background-color: ${palette.neutral003};
  width: 1px;
  height: 18px;
`;

const QRCodeContainer = styled.div`
  width: 100%;
  background-color: ${palette.white};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.75rem 0px;
`;

const QRCodeWrapper = styled.div`
  aspect-ratio: 1 / 1;
  background-color: ${palette.neutral001};
  border-radius: 10px;
`;

export default TicketItem;
