import * as amplitude from '@amplitude/analytics-browser';

export const initAmplitude = () => {
  if (!process.env.REACT_APP_LOCAL) {
    amplitude.init('43c727edd9a3867b5f2517f50a1ddbfe');
  }
};

export const appOpenLog = (isAppQuery) => {
  if (!process.env.REACT_APP_LOCAL) {
    amplitude.logEvent('App Open', {
      type: isAppQuery ? 'app' : 'web',
    });
  }
};

export const purchaseButtonClickLog = (spaceTitle, title) => {
  if (!process.env.REACT_APP_LOCAL) {
    amplitude.logEvent('Click Purchase Button', {
      spaceTitle: spaceTitle,
      productTitle: title,
    });
  }
};

export const paymentButtonClickLog = (spaceTitle, title) => {
  if (!process.env.REACT_APP_LOCAL) {
    amplitude.logEvent('Click Payment Button', {
      spaceTitle: spaceTitle,
      productTitle: title,
    });
  }
};

export const viewPageLog = (pageTitle, itemId = '0') => {
  if (!process.env.REACT_APP_LOCAL) {
    amplitude.logEvent('View Page', {
      page: pageTitle,
      item: itemId, // spaceContainer 에서는 space id , ProductDetailContainer 에서는 product id , purchaseContainer 에서는 product id
    });
  }
}
