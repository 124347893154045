import { Carousel } from 'react-responsive-carousel';
import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled, { css } from 'styled-components';
import img from 'assets/img/no_img_lg.webp';
import { palette } from 'modules/defines/styles';
import {
  formatAddress,
  getOptionFinalPrice,
  printCleanDiscount,
} from 'utils/util';
import { addCommaToNum } from 'modules/functions/utils';
import { useNavigate } from 'react-router-dom';
import { pagePaths } from 'modules/defines/paths';
import { LoadingSkeleton } from './LoadingSkeleton';
import ProductItem from './ProductItem';

const ImageCarousel = ({ productImage, spaceImages }) => {
  let images = Array.isArray(spaceImages) ? spaceImages : [];
  if (productImage) {
    images = [productImage, ...images];
  }
  const [selectedItem, setSelectedItem] = useState(0);

  useEffect(() => {
    setSelectedItem(0);
  }, [spaceImages]);

  return (
    <CarouselWrapper>
      <ImageIndicator location={'bottom'}>
        {selectedItem + 1} / {images.length}
      </ImageIndicator>
      <StyledCarousel
        selectedItem={selectedItem}
        onChange={(index) => setSelectedItem(index)}
        showIndicators={false}
      >
        {images.map((image, index) => (
          <div key={index}>
            <ImageContainer
              src={image}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = img;
              }}
            />
          </div>
        ))}
      </StyledCarousel>
    </CarouselWrapper>
  );
};

const ManagerProductsCarousel = ({ relatedProducts }) => {
  const [selectedItem, setSelectedItem] = useState(0);

  useEffect(() => {
    setSelectedItem(0);
  }, [relatedProducts]);
  // const navigate = useNavigate();
  const onClickProduct = (e, pid) => {
    e.preventDefault();
    window.location.href = pagePaths.product.replace(':pid', pid.toString());
    // navigate(pagePaths.product.replace(':pid', pid.toString()));
  };
  return (
    <>
      <CarouselSectionHeader>같은 공간 다른 상품</CarouselSectionHeader>
      <StyledProductCarousel
        selectedItem={selectedItem}
        onChange={(index) => setSelectedItem(index)}
      >
        {relatedProducts.map(
          (item, index) =>
            item.img && (
              <ManagerProductCard
                key={index}
                onClick={(e) => onClickProduct(e, item.productId)}
              >
                <ManagerProductImage src={item.img} alt={item.title} />
                <ManagerProductInfo>
                  <ManagerProductPriceRow>
                    <ManagerProductPriceDiscountRate>
                      {printCleanDiscount(item.discount)}%
                    </ManagerProductPriceDiscountRate>
                    <ManagerProductPrice>
                      <span style={{ marginRight: '1px' }}>
                        {addCommaToNum(
                          getOptionFinalPrice(item.discount, item.price, 0, 1)
                        )}
                      </span>
                      원
                    </ManagerProductPrice>
                  </ManagerProductPriceRow>
                  <ManagerProductTitle>{item.title}</ManagerProductTitle>
                </ManagerProductInfo>
              </ManagerProductCard>
            )
        )}
      </StyledProductCarousel>
    </>
  );
};

// 추천 상품 (Product Detail 페이지 하단) Carousel
const OtherProductsCarousel = ({ otherProducts }) => {
  const [selectedItem, setSelectedItem] = useState(0);

  useEffect(() => {
    setSelectedItem(0);
  }, [otherProducts]);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    // 미디어 쿼리가 변경될 때 호출되는 콜백 함수
    const handleMediaChange = (e) => {
      setIsMobile(e.matches);
    };

    // 미디어 쿼리 감시자 등록
    mediaQuery.addEventListener('change', handleMediaChange);

    // 컴포넌트가 언마운트될 때 감시자 제거
    return () => {
      mediaQuery.removeEventListener('change', handleMediaChange);
    };
  }, []);
  return (
    <>
      <CarouselSectionHeader>추천 상품</CarouselSectionHeader>
      <StyledProductCarousel
        selectedItem={selectedItem}
        onChange={(index) => setSelectedItem(index)}
        marginBottom={'150px'}
        gap={'3px'}
        noPadding={true}
      >
        {otherProducts.map(
          (item, index) =>
            item.img && (
              <ProductItemWrapper>
                <ProductItem
                  key={`${item.id}_${index}`}
                  productId={item.productId}
                  category={item.category}
                  space_title={item.space}
                  title={item.title}
                  discount={item.discount}
                  price={item.price}
                  img={item.img}
                  type={'main'}
                  width={isMobile ? '180px' : '213px'}
                />
              </ProductItemWrapper>
              // <OtherProductCard
              //   key={index}
              //   onClick={(e) => onClickProduct(e, item.productId)}
              //   imageUrl={item.img}
              // >
              //   {/* <OtherProductImage src={item.img} alt={item.title} /> */}

              //   <OtherProductInfo>
              //     <OtherProductSpaceTitle>{item.space}</OtherProductSpaceTitle>
              //     <OtherProductTitle>{item.title}</OtherProductTitle>
              //     <OtherProductPriceRow>
              //       <OtherProductPriceDiscountRate>
              //         {printCleanDiscount(item.discount)}%
              //       </OtherProductPriceDiscountRate>
              //       <OtherProductPrice>
              //         <span style={{ marginRight: '1px' }}>
              //           {addCommaToNum(
              //             getOptionFinalPrice(item.discount, item.price, 0, 1)
              //           )}
              //         </span>
              //         원
              //       </OtherProductPrice>
              //     </OtherProductPriceRow>
              //   </OtherProductInfo>
              // </OtherProductCard>
            )
        )}
      </StyledProductCarousel>
    </>
  );
};

const MainSpaceCarousel = ({ spaceInfos, isLoading }) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const totalSpaceNum = spaceInfos ? spaceInfos.length : 0;
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedItem(0);
  }, [spaceInfos]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedItem((prevSelectedItem) =>
        prevSelectedItem === totalSpaceNum - 1 ? 0 : prevSelectedItem + 1
      );
    }, 4000);
    return () => clearInterval(interval);
  }, [totalSpaceNum]);

  return !isLoading ? (
    <CarouselWrapper>
      <ImageIndicator location={'top'}>
        {selectedItem + 1} / {totalSpaceNum}
      </ImageIndicator>
      <StyledCarousel
        selectedItem={selectedItem}
        onChange={(index) => setSelectedItem(index)}
        width={'100%'}
        showIndicators={false}
      >
        {spaceInfos &&
          spaceInfos.map((spaceInfo, index) => {
            return (
              <div
                key={index}
                style={{ position: 'relative', cursor: 'pointer' }}
                onClick={
                  () =>
                    (window.location.href = pagePaths.space.replace(
                      ':sid',
                      spaceInfo.id.toString()
                    ))
                  // navigate(
                  //   pagePaths.space.replace(':sid', spaceInfo.id.toString())
                  // )
                }
              >
                <MainImageContainer>
                  <img
                    src={spaceInfo.img_webp}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = img;
                    }}
                  />
                </MainImageContainer>
                <MainStoreInfoHeader>
                  <MainStoreInfoHeaderCategory>
                    {spaceInfo.category_str ? spaceInfo.category_str : '기타'}
                  </MainStoreInfoHeaderCategory>
                  <MainStoreInfoHeaderDiscount>
                    <FontDanger>
                      {Math.round(spaceInfo.discount_max)}%
                    </FontDanger>{' '}
                    할인
                  </MainStoreInfoHeaderDiscount>
                </MainStoreInfoHeader>
                <MainStoreInfoBottom>
                  <MainStoreInfoBottomAddress>
                    {formatAddress(spaceInfo.address)}
                  </MainStoreInfoBottomAddress>
                  <MainStoreInfoBottomTitle>
                    {spaceInfo.title}
                  </MainStoreInfoBottomTitle>
                </MainStoreInfoBottom>
              </div>
            );
          })}
      </StyledCarousel>
    </CarouselWrapper>
  ) : (
    <LoadingSkeleton type="main-space" />
  );
};

const ProductItemWrapper = styled.div`
  width: 320px;
`;

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledCarousel = styled(Carousel)`
  width: 100%;

  .carousel .control-arrow {
    background: none;
    -webkit-tap-highlight-color: transparent;
    &:hover {
      background: none;
    }
    &:focus {
      background: none;
      outline: none;
    }
    &:active {
      background: none;
    }
    &:focus-visible {
      background: none;
      outline: none;
    }
  }
  .carousel-status {
    display: none;
  }
  .carousel .control-arrow:focus {
    outline: none;
    box-shadow: none;
    background: none;
  }

  .carousel .control-dots {
    margin: 0;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .carousel .control-dots .dot {
    background-color: #c1c0c9;
    width: 5px;
    height: 5px;
    margin: 0 5px;
    box-shadow: none;
  }
  .carousel .control-dots .dot.selected {
    background-color: #0097ff;
    border-radius: 15px;
    width: 15px;
    height: 5px;
    box-shadow: none;
  }
  .carousel .thumbs-wrapper {
    margin: 0;
  }
`;

const ImageContainer = styled.img`
  width: 100%;
  // aspect-ratio: 5 / 4;
  aspect-ratio: 1 / 1; // FIXMEFIXME 이미지 비율
  object-fit: cover;
`;

const MainImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 5 / 4;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 50.64%,
      rgba(0, 0, 0, 0.8) 85%
    );
    pointer-events: none; /* 이 속성은 이미지 위의 그라디언트가 클릭 이벤트를 방해하지 않도록 합니다 */
  }
`;
const MainStoreInfoHeader = styled.div`
  height: 32px;
  top: 0px;
  left: 0px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const MainStoreInfoHeaderCategory = styled.div`
  color: ${palette.fontWhite};

  /* Badge */
  font-family: Pretendard;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  padding: 0px 12px;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  display: flex;
  align-items: center;
`;
const MainStoreInfoHeaderDiscount = styled.div`
  color: ${palette.neutral007};

  /* Button */
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 117.647% */
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 0px 8px;
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  align-items: center;
`;
const FontDanger = styled.div`
  color: ${palette.danger};
`;
const MainStoreInfoBottom = styled.div`
  position: absolute;
  left: 25px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
`;
const MainStoreInfoBottomAddress = styled.div`
  color: ${palette.fontWhite};

  /* Badge */
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */

  padding: 2px 0px;
`;
const MainStoreInfoBottomTitle = styled.div`
  color: ${palette.fontWhite};

  /* H_PageTitle-20 */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
`;
const ImageIndicator = styled.div`
  z-index: 1;
  position: absolute;
  right: 1.125rem;
  ${(props) => props.location === 'top' && 'top: 1.125rem;'};
  ${(props) => props.location === 'bottom' && 'bottom: 1.125rem;'};
  display: flex;
  padding: 3px 10px;
  color: ${palette.fontWhite};
  text-align: center;

  /* Badge */
  font-family: Pretendard;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 160% */

  border-radius: 100px;
  background: var(--opacity-008, rgba(30, 30, 30, 0.4));
`;

// 새로운 이미지 스타일 캐로셀
const StyledProductCarousel = styled.div`
  display: flex;
  overflow-x: auto;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '40px'};
  padding: 0px 25px;
  @media (max-width: 500px) {
    ${(props) =>
      props.noPadding &&
      css`
        padding: 0px;
      `}
  }
  gap: ${(props) => (props.gap ? props.gap : '8px')};
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ManagerProductCard = styled.div`
  cursor: pointer;
`;

const ManagerProductImage = styled.img`
  width: 160px;
  height: 160px;
  object-fit: cover;
  border-radius: 10px;
`;

const ManagerProductInfo = styled.div`
  margin-top: 10px;
`;

const ManagerProductPriceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  font-size: 1.0625rem;
  font-weight: 700;
  line-height: 1.25rem;
`;
const ManagerProductPriceDiscountRate = styled.div`
  color: #0097ff;
`;
const ManagerProductPrice = styled.div`
  margin-left: 2px;
  color: ${palette.fontBlack};
`;

const ManagerProductTitle = styled.div`
  width: 160px;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.25rem;
  font-weight: 500;
  color: ${palette.neutral005};
`;

const OtherProductCard = styled.div`
  cursor: pointer;
  width: 306px;
  min-width: 306px;
  height: 320px;
  object-fit: cover;
  border-radius: 10px;
  position: relative;
  background: ${({ imageUrl }) =>
    `linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 50.64%,
      rgba(0, 0, 0, 0.8) 85%
    ), url(${imageUrl}) no-repeat center/cover`};
  background-size: cover;
`;

const OtherProductInfo = styled.div`
  position: absolute;
  bottom: 20px;
  left: 25px;
  z-index: 2;
`;

const OtherProductPriceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
`;
const OtherProductPriceDiscountRate = styled.div`
  color: #0097ff;
`;
const OtherProductPrice = styled.div`
  margin-left: 2px;
  color: ${palette.white};
`;
const OtherProductSpaceTitle = styled.div`
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${palette.white};
  margin-bottom: 3px;
`;

const OtherProductTitle = styled.div`
  width: 240px;
  font-size: 1.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5rem;
  font-weight: 700;
  margin-bottom: 5px;
  color: ${palette.white};
`;

const CarouselSectionHeader = styled.div`
  margin: ${(props) => (props.margin ? props.margin : '0 25px 20px')};
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: ${palette.neutral006};
`;

export {
  ImageCarousel,
  ManagerProductsCarousel,
  OtherProductsCarousel,
  MainSpaceCarousel,
};
