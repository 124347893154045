import { palette } from 'modules/defines/styles';
import styled, { createGlobalStyle } from 'styled-components';
import PretendardBlack from 'assets/fonts/pretendard/Pretendard-Black.woff';
import PretendardExtraBold from 'assets/fonts/pretendard/Pretendard-ExtraBold.woff';
import PretendardBold from 'assets/fonts/pretendard/Pretendard-Bold.woff';
import PretendardSemiBold from 'assets/fonts/pretendard/Pretendard-SemiBold.woff';
import PretendardRegular from 'assets/fonts/pretendard/Pretendard-Regular.woff';
import PretendardMedium from 'assets/fonts/pretendard/Pretendard-Medium.woff';
import PretendardLight from 'assets/fonts/pretendard/Pretendard-Light.woff';
import PretendardExtraLight from 'assets/fonts/pretendard/Pretendard-ExtraLight.woff';
import PretendardThin from 'assets/fonts/pretendard/Pretendard-Thin.woff';
import { ScrollRestoration } from 'react-router-dom';

export const FontStyle = createGlobalStyle`

  @font-face {
    font-family: "Pretendard";
    font-weight: 900;
    src: url(${PretendardBlack}) format("woff");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 800;
    src: url(${PretendardExtraBold}) format("woff");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 700;
    src: url(${PretendardBold}) format("woff");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 600;  
    src: url(${PretendardSemiBold}) format("woff");
  }
  
  @font-face {
    font-family: "Pretendard";
    font-weight: 500;

    src: url(${PretendardMedium}) format("woff");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 400;
    src: url(${PretendardRegular}) format("woff");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 300;
    src: url(${PretendardLight}) format("woff");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 200;
    src: url(${PretendardExtraLight}) format("woff");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 100;
    src: url(${PretendardThin}) format("woff");
  }

  :root {
    font-size: clamp(12px, 3.73832vw, 16px);
    -webkit-tap-highlight-color: transparent;
  }

  button {
    -webkit-appearance: none;
  }
  *{
   
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  html{

  }
  
  body {
   
    font-family: Pretendard;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: never;
    -webkit-text-size-adjust : none;  /* 크롬, 사파리, 오페라 신버전 */
    -ms-text-size-adjust : none;  /* IE */
    -moz-text-size-adjust : none;  /* 파이어폭스 */
    -o-text-size-adjust : none;  /* 오페라 구버전 */
  }
`;
const GlobalContainer = ({ children, padding = true }) => {
  return (
    <GlobalWrapper>
      <ScrollRestoration />
      <FontStyle />
      <ContentsWrapper isPadding={padding}>{children}</ContentsWrapper>
    </GlobalWrapper>
  );
};

const GlobalWrapper = styled.div`
  position: relative;

  padding: 0px;
  margin: 0 auto;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  background-color: ${palette.white};
  box-shadow: 0 0 20px 0 #e6e6e6;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  overflow-x: hidden;
`;

const ContentsWrapper = styled.div`
  padding: ${(props) => (props.isPadding ? '2rem' : '0')};
  @media screen and (max-width: 500px) {
    padding: 0;
  }
`;

export default GlobalContainer;
