import RouterComponent from './routes';
import GlobalStyles from 'modules/defines/styles';
import { initAmplitude } from 'utils/amplitude';
import { HelmetProvider } from 'react-helmet-async';
import { NavermapsProvider } from 'react-naver-maps';
import { facebookPixelInit } from 'utils/facebookPixel';
function App() {
  initAmplitude();
  facebookPixelInit();
  return (
    <>
      <HelmetProvider>
        <NavermapsProvider
          ncpClientId="wwyebba9sr"
          // or finClientId, govClientId
        >
          <GlobalStyles />
          <RouterComponent />
        </NavermapsProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
