import { TextButton, palette } from 'modules/defines/styles';
import styled, { css } from 'styled-components';

// Button / Solid / 60
export const ButtonSolid60 = styled.button<{
  btype: string;
  fixed?: boolean;
  marginTop?: string;
}>`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 3.75rem;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;

  color: ${palette.white};

  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem; /* 117.647% */

  ${(props) =>
    props.btype === 'primary' &&
    css`
      background-color: ${palette.primary};
      &:active {
        background-color: ${palette.primaryHover};
      }
    `}
  ${(props) =>
    props.btype === 'secondary' &&
    css`
      background-color: ${palette.neutral007};
      &:active {
        background-color: ${palette.secondaryHover};
      }
      &:disabled {
        background-color: ${palette.neutral002};
        pointer-events: none;
      }
    `}

  ${(props) =>
    props.fixed &&
    css`
      position: fixed;
      bottom: 20px;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
      width: calc(100% - 50px);
      max-width: 450px;
    `}
  
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}
`;

// Button / text / Regular
export const ButtonTextRegular = styled.button<{
  btype: string;
  fixed?: boolean;
  disabled?: boolean;
}>`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  padding: 0.375rem 0.625rem;

  color: ${palette.neutral007};
  ${TextButton};
  border-radius: 10px;

  &:active {
    background-color: ${palette.neutral001};
  }

  ${(props) =>
    props.btype === 'primary' &&
    css`
      color: ${palette.primary};
    `};
  ${(props) =>
    props.btype === 'secondary' &&
    css`
      color: ${palette.neutral007};
    `};
  ${(props) =>
    props.btype === 'tertiary' &&
    css`
      color: ${palette.neutral005};
      &:active {
        color: ${palette.neutral004}; // 디자인 수정 시 변경
      }
    `};

  ${(props) =>
    props.fixed &&
    css`
      position: fixed;
      bottom: 90px;
      left: 50%;
      z-index: 2;
      transform: translateX(-50%);
      width: calc(100% - 50px);
      max-width: 450px;
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      &:active {
        background-color: ${palette.white};
      }
    `}
`;
