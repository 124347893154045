import { Badge, SubTitle, palette } from 'modules/defines/styles';
import { Fragment } from 'react/jsx-runtime';
import styled from 'styled-components';

interface DeadlineProps {
  category?: '푸드' | '굿즈' | '전시';
  expireAt: string;
}

const DeadlineCommentGroup = ({ category, expireAt }: DeadlineProps) => {
  switch (category) {
    case '푸드':
    case '굿즈':
      return '구매일로부터 30일';
    case '전시':
      return `전시 종료일(${expireAt})까지`;
    default:
      return null;
  }
};

const Deadline: React.FC<DeadlineProps> = (props) => {
  const { category, expireAt } = props;

  return (
    <DeadlineWrapper>
      <DeadlineComment>
        {DeadlineCommentGroup({ category, expireAt })}
      </DeadlineComment>
      {!expireAt || expireAt === '없음' ? null : (
        <ExpireAt>
          {expireAt}
          까지
        </ExpireAt>
      )}
    </DeadlineWrapper>
  );
};

const DeadlineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.25rem;
`;
const DeadlineComment = styled.div`
  ${SubTitle};
  color: ${palette.neutral006};
`;

const ExpireAt = styled.time`
  ${Badge};
  color: ${palette.neutral006};
`;

export default Deadline;
