import Icon from 'assets/icon/Icon';
import { palette } from 'modules/defines/styles';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FontStyle } from './GlobalContainer';
interface LocationState {
  type: 'fail';
}
const StateContainer: React.FC<LocationState> = ({ type }) => {
  return (
    <Container>
      <FontStyle />
      {type === 'fail' && <Icon icon="wrong" fill="#D9D9D9" />}

      <Text>
        {type === 'fail' && `잘못된 접근입니다.\n 다시 확인해 주세요.`}
      </Text>
    </Container>
  );
};

const Text = styled.h1`
  white-space: pre-line;
  color: #1e1e1e;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem; /* 120% */
`;

const Container = styled.div`
  padding: 0 1.5rem;
  /* background-color: red; */
  margin: 0 auto;
  max-width: 40rem;
  min-height: 100dvh;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${palette.white};
  box-shadow: 0 0 20px 0 #e6e6e6;
`;

export default StateContainer;
