import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

interface ToastMessageProps {
  message: string;
  show: boolean;
  duration?: number;
}

const ToastMessage: React.FC<ToastMessageProps> = ({
  message,
  show,
  duration = 3000,
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (show) {
      setVisible(true);
      timer = setTimeout(() => {
        setVisible(false);
      }, duration);
    } else {
      setVisible(false);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [show, duration]);

  return <ToastText visible={visible}>{message}</ToastText>;
};

const slideIn = keyframes`
  from {
    transform: translateX(-50%) translateY(20px);
  }
  to {
    transform: translateX(-50%) translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(-50%) translateY(0);
  }
  to {
    transform: translateX(-50%) translateY(20px);
  }
`;

const ToastText = styled.div<{ visible: boolean }>`
  font-family: Pretendard;
  position: fixed;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 12px 20px;
  border-radius: 5px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  transition:
    opacity 0.5s ease-in-out,
    visibility 0.5s ease-in-out;
  z-index: 1000;
  font-size: 1rem;
  font-weight: 600;
  animation: ${({ visible }) => (visible ? slideIn : slideOut)} 0.5s ease-in-out;

  @media screen and (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export default ToastMessage;
