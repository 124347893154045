/* eslint-disable @typescript-eslint/no-redeclare */
import SVGICON from './icon.json';
import styled, { CSSProp } from 'styled-components';

const ICON_GROUP = {
  redo: 'redo',
  arrowBubble: 'arrowBubble',
  showPassword: 'showPassword',
  hiddenPassword: 'hiddenPassword',
  circlePlus: 'circlePlus',
  tooltip: 'tooltip',
  noChecked: 'noChecked',
  checked: 'checked',
  calendar: 'calendar',
  arrowRight: 'arrowRight',
  arrowDown: 'arrowDown',
  checkArrow: 'checkArrow',
  check: 'check',
  wrong: 'wrong',
  plus: 'plus',
  minus: 'minus',
} as const;

export type ICON_GROUP = (typeof ICON_GROUP)[keyof typeof ICON_GROUP];

interface IconProps {
  icon: ICON_GROUP;
  fill: string;
  onclick?: () => void;
  css?: CSSProp;
}
const Icon: React.FC<IconProps> = ({ icon, fill, onclick, css }) => {
  const { name } = SVGICON[icon];
  const { width, height, viewBox, path } = SVGICON[icon].size;

  return (
    <IconWrapper css={css} onclick={onclick} onClick={onclick} id={name}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fillRule="evenodd" clipRule="evenodd" d={path} fill={fill} />
      </svg>
    </IconWrapper>
  );
};

const IconWrapper = styled.i<{
  onclick: (() => void) | undefined;
  css?: CSSProp;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => props.onclick !== undefined && 'pointer'};

  ${(props) => props.css}
`;
export default Icon;
