import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SubTitle, palette } from 'modules/defines/styles';

const Tab = styled.button`
  box-sizing: border-box;
  color: ${palette.neutral005};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.33%;
  height: 44px;

  text-align: center;
  & > span {
    padding: 0.3125rem 0.75rem;
    ${SubTitle};
  }
  position: relative;
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    background: ${palette.neutral002};
    transition: width 0.3s;
  }

  &.active {
    color: #1e1e1e;
    position: relative;
    & > span {
      padding: 0.3125rem 0.75rem;
      ${SubTitle};
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      bottom: 0;
      background: ${palette.primary};
      transition: width 0.3s;
    }
  }

  &:disabled {
    color: ${palette.secondary002};
    position: relative;
    & > span {
      padding: 0.3125rem 0.75rem;
      ${SubTitle};
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      bottom: 0;
      background: ${palette.neutral002};
      transition: width 0.3s;
    }
    pointer-events: none;
  }
  cursor: pointer;
`;

const TabBarWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 20;
`;

type TabBarProps = {
  active: 'total' | 'usable' | 'unusable';
  setActive: (active: 'total' | 'usable' | 'unusable') => void;
  usableLength: number;
  unusableLength: number;

  callback?: () => void;
};
const TabBar: React.FC<TabBarProps> = ({
  active,
  setActive,
  unusableLength,
  usableLength,
}) => {
  return (
    <TabBarWrapper>
      <Tab
        onClick={() => setActive('total')}
        className={active === 'total' ? 'active' : ''}
      >
        <span>전체</span>
      </Tab>
      <Tab
        disabled={usableLength === 0}
        onClick={() => setActive('usable')}
        className={active === 'usable' ? 'active' : ''}
      >
        <span>사용 가능</span>
      </Tab>
      <Tab
        disabled={unusableLength === 0}
        onClick={() => setActive('unusable')}
        className={active === 'unusable' ? 'active' : ''}
      >
        <span>사용 완료</span>
      </Tab>
    </TabBarWrapper>
  );
};

export default TabBar;
