import styled, { keyframes } from 'styled-components';
import { useParams } from 'react-router';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { formatExpireDateStr } from 'utils/util';
import GlobalContainer from './GlobalContainer';
import {
  Body_Text_16,
  HPageTitle20,
  H_SpaceName,
  palette,
} from 'modules/defines/styles';
import { useRef, useEffect, useState } from 'react';
import { productAPI } from 'api';
import { allowScroll, preventScroll } from 'utils/handleScroll';
import BottomSheetDefault from 'components/ProductDetail/BottomSheetDefault';
import { ButtonSolid60 } from 'components/common/Button';
import parse from 'html-react-parser';
import ScrollTopButton from 'components/common/ScrollTopButton';
import { convertToWebp } from 'modules/functions/utils';
import {
  icon_back,
  icon_back_white,
  icon_export,
  icon_export_white,
  icon_home,
} from 'assets/icon';
import {
  ImageCarousel,
  ManagerProductsCarousel,
  OtherProductsCarousel,
} from 'components/common/Carousel';
import { formatAddress, printCleanDiscount } from 'utils/util';
import { css } from 'styled-components';
import RefundInfo from 'components/ProductDetail/RefundInfo';
import {
  appOpenLog,
  purchaseButtonClickLog,
  viewPageLog,
} from 'utils/amplitude';
import ProductInfo from 'components/ProductDetail/ProductInfo';
import { UserAgent } from 'utils/userAgent';
import { Helmet } from 'react-helmet-async';
import SpaceFAVContents from 'components/ProductDetail/SpaceFAVContents';
import { SpaceTitleLabel } from 'components/common/SpaceCommon';
import ProductOptionList from 'components/ProductDetail/ProductOptionList';
import AppBanner from 'components/common/AppBanner';
import SpaceInfo from 'components/ProductDetail/SpaceInfo';
import {
  facebookPixelTrack,
  useFacebookPixelPageView,
} from 'utils/facebookPixel';
import Deadline from 'components/common/Deadline';
import { Player } from '@lottiefiles/react-lottie-player';
import { spinner } from 'assets/lottie';
import ToastMessage from 'components/common/ToastMessage';
import { SafeAreaInAppAndroid } from 'components/common/Common';

const ProductDetailContainer = () => {
  const navigate = useNavigate();
  const { os, browser } = UserAgent;
  const { pid: productId } = useParams();
  const [product, setProduct] = useState();
  const [space, setSpace] = useState();
  const [dataLoading, setDataLoading] = useState(true);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [expand, setExpand] = useState(false);
  const [images, setImages] = useState();
  const [searchParams] = useSearchParams();
  const isAppQuery = searchParams.get('type') || null;
  const uidFromApp = searchParams.get('uid') || 0; // 앱에서는 해당 uid로 작동하도록, 웹에서는 null
  const [showFixedBtn, setShowFixedBtn] = useState(false);

  const [shareBtnLoading, setShareBtnLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);

  // prevent scroll : (Bottom Sheet ON)
  useFacebookPixelPageView();
  useEffect(() => {
    appOpenLog(isAppQuery);
    viewPageLog('product_detail', productId); //amplitude tracking
  }, []);

  useEffect(() => {
    if (showBottomSheet) {
      const prevScrollY = preventScroll();
      return () => {
        allowScroll(prevScrollY);
      };
    }
  }, [showBottomSheet]);

  // Data Initialize
  useEffect(() => {
    if (productId !== undefined) {
      setDataLoading(true);
      productAPI.getOne(productId).then((item) => {
        setProduct({
          id: item.data?.product.id,
          productId: item.data?.product.productId,
          title: item.data?.product.title,
          description: item.data?.product.description,
          img: convertToWebp(item.data?.product.img ?? ''),
          category: item.data?.product.category,
          count: item.data?.product.count, // 총 재고
          countByOptions: item.data?.product.countByOptions, // 옵션 별 재고 관리
          details: item.data?.product.details
            ? item.data?.product.details.split('///').map(convertToWebp)
            : [],
          price: item.data?.product.price,
          discount: item.data?.product.discount,
          priceFinal: item.data?.product.priceFinal,
          status: item.data?.product.status,
          options: item.data?.product.options,
          type: item.data?.product.type,
          isBilling: item.data?.product.isBilling,
          managerId: item.data?.product.manager_id,
          manager: item.data?.product.manager,
          contents_desc: item.data?.product.contents?.product_contents,
          contents_refund_policy: item.data?.product.contents?.refund_policy,
          contents: item.data?.product.contents,
          content_type: item.data?.product.content_type,
          adultOnly: item.data?.product.adultOnly,
          period: item.data?.product.period,
          managerProducts: item.data?.product.managerProducts ?? [],
          otherProducts: item.data?.product.otherProducts ?? [],
        });
        setSpace({
          ...item.data?.space,
          img: convertToWebp(item.data?.space.img ?? ''),
          menuImages: item.data?.space.menuImages.map(convertToWebp),
          defaultImages: item.data?.space.defaultImages.map(convertToWebp),
          time: item.data?.space.time.split('\n'),
          phone: formatPhoneNumber(item.data?.space.phone),
        });
      });
      setExpand(false);
    }
  }, [productId]);
  useEffect(() => {
    if (product && space) {
      var defaultIamges = space.defaultImages;
      var productImages = product.details;
      setImages([...productImages, ...defaultIamges].slice(0, 10));
      setDataLoading(false);
    }
  }, [product, space]);

  const [isSticky, setIsSticky] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const tabRef = useRef(null);
  const detailRef = useRef(null);
  const noticeRef = useRef(null);
  const handleScroll = () => {
    if (tabRef.current) {
      setIsSticky(tabRef.current.getBoundingClientRect().top <= 0);
    }
    const detailOffset = detailRef.current?.offsetTop - 70;
    const noticeOffset = noticeRef.current?.offsetTop - 70;
    const scrollPosition = window.scrollY;

    if (scrollPosition >= detailOffset && scrollPosition < noticeOffset) {
      setActiveSection('detail');
    } else if (scrollPosition >= noticeOffset) {
      setActiveSection('notice');
    } else {
      setActiveSection('');
    }
    // 구매하기 버튼 보여주기
    if (window.scrollY > 700) {
      setShowFixedBtn(true);
    } else {
      setShowFixedBtn(false);
    }
  };

  const formatPhoneNumber = (number) => {
    if (number.startsWith('0')) {
      return `+82${number.substring(1).replaceAll('-', '')}`;
    }
    return `+82${number.replaceAll('-', '')}`;
  };

  const scrollToSection = (ref) => {
    //! 유의 사항 없을시 에러 방지
    window.scrollTo({
      top: ref.current ? ref.current.offsetTop - 60 : undefined,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // < onClick Button > ////////
  const onClickPurchaseButton = (e) => {
    e.preventDefault();

    setShowBottomSheet(true);
  };

  const onClickBottomSheetClose = () => {
    setShowBottomSheet(false);
  };

  const handleSendInfoToAmplitudeAndFacebookPixel = () => {
    purchaseButtonClickLog(space.title, product.title);
    facebookPixelTrack('Click Purchase Button', {
      spaceTitle: space.title,
      productTitle: product.title,
    });
  };
  const copyAddressToClipboard = (copyText) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          // alert('텍스트가 클립보드에 복사되었습니다!');
          // FIXME : alert 대신 toast message로 변경
          // console.log('copied: ', copyText);
        })
        .catch((err) => {
          console.error('클립보드에 복사 실패:', err);
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = copyText;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        // alert('텍스트가 클립보드에 복사되었습니다!');
        // FIXME : alert 대신 toast message로 변경
      } catch (err) {
        console.error('클립보드에 복사 실패:', err);
      }
      document.body.removeChild(textArea);
    }
  };

  // 공유하기 버튼
  const handleShareBtn = async () => {
    setShareBtnLoading(true);

    await productAPI
      .getShareLink({ id: product.productId })
      .then((res) => {
        if (res.data.success) {
          copyAddressToClipboard(res.data.share_link);
        }
      })
      .finally(() => {
        setShareBtnLoading(false);
        setShowToast(true);

        setTimeout(() => {
          setShowToast(false);
        }, 1500);
      });
  };

  return (
    <GlobalContainer padding={false}>
      <Helmet>
        <meta
          property="product:retailer_item_id"
          content={`${product?.productId}`}
        />
        <meta
          property="og:title"
          content={`[${space?.title}] ${product?.title}`}
        />
        <meta property="product:brand" content={space?.title} />
        <meta property="og:description" content={product?.description} />
        <meta property="product:condition" content="new" />
        <meta property="og:price:currency" content="KRW" />
        <meta property="product:price:currency" content="KRW" />
        <meta property="og:price:amount" content={product?.price} />
        <meta property="product:price:amount" content={product?.price} />
        <meta property="product:sale_price_currency" content="KRW" />
        <meta
          property="product:availability"
          content={product?.count <= 0 ? 'out of stock' : 'in stock'}
        />
        <meta
          property="product:sale_price:amount"
          content={product?.priceFinal}
        />
        <meta
          property="og:url"
          content={`https://store.favapp.io${window.location.pathname}`}
        />
        <meta property="og:image" content={space?.img} />
        {(product?.category === '푸드' || product?.category === '카페') && (
          <meta
            property="product:google_product_category"
            content="Food, Beverages & Tobacco"
          />
        )}
        {(product?.category === '푸드' || product?.category === '카페') && (
          <meta
            property="product:fb_product_category"
            content="food & beverages > food"
          />
        )}
      </Helmet>
      {product && product.status !== 4 ? (
        <WrongProduct>잘못된 접근입니다</WrongProduct>
      ) : (
        <>
          <ToastMessage message="클립보드에 복사되었습니다" show={showToast} />
          {shareBtnLoading && (
            <ShareLoadingContainer>
              <Player
                style={{
                  width: '40px',
                  height: '40px',
                }}
                id="spinner_player"
                src={spinner}
                autoplay
                loop
              />
            </ShareLoadingContainer>
          )}
          <ScrollTopButton />

          <AppBanner os={os} browser={browser} type={'float'} />
          {browser.isFavApp ? (
            <>
              {os.isAndroid && <SafeAreaInAppAndroid />}
              <FixedContainer isFavApp={browser.isFavApp}>
                <HeaderContainer isFavApp={browser.isFavApp}>
                  <IconContainer>
                    <IconWrapper
                      onClick={() => navigate(-1)}
                      src={icon_back}
                      alt="icon_back"
                    />
                  </IconContainer>

                  {!dataLoading ? (
                    <HeaderTitle>{space.title}</HeaderTitle>
                  ) : (
                    <HeaderTitle></HeaderTitle>
                  )}
                  <IconContainerRight>
                    <IconWrapper
                      onClick={() => navigate('/')}
                      src={icon_home}
                      alt="icon_home"
                    />
                    <IconWrapper
                      // onClick={() => navigate(-1)}
                      onClick={() => handleShareBtn()}
                      src={icon_export}
                      alt="icon_export"
                    />
                  </IconContainerRight>
                </HeaderContainer>
              </FixedContainer>
            </>
          ) : (
            <AlphaHeaderContainer>
              <FloatButtonWrapper
                src={icon_back_white}
                onClick={() => navigate(-1)}
                alt="icon_back_float"
              />
              <FloatButtonWrapper
                src={icon_export_white}
                onClick={() => handleShareBtn()}
                alt="icon_export"
              />
            </AlphaHeaderContainer>
          )}

          {/* 상품 정보 */}
          <ContentsTitleContainer>
            {/* 상품 이미지 */}
            {product && space.defaultImages ? (
              <ImageCarousel
                productImage={product.img}
                spaceImages={space.defaultImages}
              />
            ) : (
              <ImageContainer />
            )}
            {/* 상품 정보 */}
            <ContentsWrapper>
              {/* 공간 이름 */}
              <SpaceTitleLabel
                className={!product ? 'loading' : ''}
                marginBottom={'4px'}
              >
                {product ? space.title : ''}
              </SpaceTitleLabel>
              {/* 카테고리 & 주소 */}
              <CategoryContainer className={!product ? 'loading' : ''}>
                <CategoryText>{product ? product.category : ''}</CategoryText>
                {!dataLoading && <CategoryDivider />}
                <CategoryText>
                  {product ? formatAddress(space.address) : ''}
                </CategoryText>
              </CategoryContainer>
              {/* 상품 이름 */}
              <TitleContainer className={!product ? 'loading' : ''}>
                {product ? product.title : ''}
              </TitleContainer>
              {/* 상품 설명 */}
              <DescContainer className={!product ? 'loading' : ''}>
                {product ? product.description : ''}
              </DescContainer>
              {/* 상품 가격 */}
              <PriceDiscountContainer>
                <PriceDiscountOriginalPrice
                  className={!product ? 'loading' : ''}
                >
                  {product
                    ? `${parseInt(product.price).toLocaleString()}원`
                    : ''}
                </PriceDiscountOriginalPrice>
                <PriceDiscountedWrapper>
                  <PriceDiscountRate className={!product ? 'loading' : ''}>
                    {product ? `${printCleanDiscount(product.discount)}%` : ''}
                  </PriceDiscountRate>
                  <PriceDiscountedPrice className={!product ? 'loading' : ''}>
                    {product
                      ? `${parseInt(product.priceFinal).toLocaleString()}원`
                      : ''}
                  </PriceDiscountedPrice>
                </PriceDiscountedWrapper>
              </PriceDiscountContainer>
              {/* 구매하기 버튼 */}
              <ButtonSolid60
                onClick={(e) => onClickPurchaseButton(e)}
                disabled={dataLoading}
                fixed={false}
                btype="secondary"
                marginTop="2rem"
              >
                구매하기
              </ButtonSolid60>
            </ContentsWrapper>
          </ContentsTitleContainer>
          {/* 이 상품은 교환권(현장수령)입니다. */}
          <NoticeWrapper>
            {product && product.type === 'ticket' ? (
              <Notice>
                이 상품은 <Highlight>교환권(현장수령)</Highlight> 입니다.
              </Notice>
            ) : (
              <SkeletonContainer></SkeletonContainer>
            )}
          </NoticeWrapper>
          {/* 같은 공간 다른 상품 */}
          {!dataLoading ? (
            product &&
            product.managerProducts &&
            product.managerProducts.length !== 0 && (
              <ManagerProductsCarousel
                relatedProducts={product.managerProducts}
              />
            )
          ) : (
            <SkeletonContainer></SkeletonContainer>
          )}
          {/* 상세내용 & 유의사항 Nav Bar */}
          {!dataLoading ? (
            <>
              {product && images && images.length !== 0 && (
                <>
                  <StickyContainer ref={tabRef}>
                    <StickyBar isSticky={isSticky}>
                      <TabButton
                        onClick={() => scrollToSection(detailRef)}
                        active={activeSection === 'detail'}
                      >
                        상세내용
                      </TabButton>
                      <TabButton
                        onClick={() => scrollToSection(noticeRef)}
                        active={activeSection === 'notice'}
                      >
                        유의사항
                      </TabButton>
                    </StickyBar>
                  </StickyContainer>
                  {isSticky && <div style={{ height: '45px' }}></div>}
                </>
              )}
            </>
          ) : (
            <SkeletonContainer></SkeletonContainer>
          )}
          {/* 구매하기 Fixed 버튼 (하단) */}
          {showFixedBtn && (
            <ButtonSolid60
              onClick={(e) => onClickPurchaseButton(e)}
              disabled={dataLoading}
              fixed={true}
              btype="secondary"
            >
              구매하기
            </ButtonSolid60>
          )}

          {/* 상세내용 & 유의사항 */}
          {!dataLoading ? (
            <>
              <DetailContainer id="detail" ref={detailRef}>
                {product.content_type === 0 ? (
                  <SpaceFAVContents space={space} />
                ) : (
                  <ContentsImageContainer expand={expand}>
                    {images !== undefined &&
                      images !== 0 &&
                      images.map((detail, idx) => {
                        return (
                          <DescImageWrapper
                            src={detail}
                            key={`detail-img-${idx}`}
                          />
                        );
                      })}
                    <ExpandButtonArea>
                      <ExpandButton onClick={() => setExpand(!expand)}>
                        {expand ? '상세 이미지 접기' : '상세 이미지 더보기'}
                      </ExpandButton>
                    </ExpandButtonArea>
                  </ContentsImageContainer>
                )}

                <ProductOptionList product={product} />
                <SpaceInfo space={space} />

                <ProductWrapper>
                  {product.content_type === 0 && (
                    <ContentsImageContainer expand={expand}>
                      {images !== undefined &&
                        images !== 0 &&
                        images.map((detail, idx) => {
                          return (
                            <DescImageWrapper
                              src={detail}
                              key={`detail-img-${idx}`}
                            />
                          );
                        })}
                      <ExpandButtonArea>
                        <ExpandButton onClick={() => setExpand(!expand)}>
                          {expand ? '상세 이미지 접기' : '상세 이미지 더보기'}
                        </ExpandButton>
                      </ExpandButtonArea>
                    </ContentsImageContainer>
                  )}
                </ProductWrapper>
              </DetailContainer>
              <DetailContainer id="notice" ref={noticeRef}>
                <ContentsDescContainer className="notice">
                  <DescTitle>유의사항</DescTitle>
                  {space.title && (
                    <>
                      <SubTitle>[사용처]</SubTitle>
                      <ProductInfo
                        spaceTitle={space.title}
                        spaceAddress={space.address}
                      />
                      <SubTitle>[유효 기간]</SubTitle>
                      <DeadLineWrapper>
                        <Deadline
                          category={product.category}
                          expireAt={formatExpireDateStr(
                            product.period && product.period.split('_')[1]
                          )}
                        />
                      </DeadLineWrapper>
                    </>
                  )}

                  {product.contents && product.contents !== '' && (
                    <>
                      <SubTitle marginTop={'40px'}>[상품 정보]</SubTitle>
                      <ContentsText>{parse(product.contents)}</ContentsText>
                    </>
                  )}
                  <SubTitle marginTop={'40px'}>[취소 및 환불]</SubTitle>
                  <RefundInfo
                    fontStyle="BodyText16"
                    category={product.category}
                    noTitle
                    marginTop="16px"
                  />
                </ContentsDescContainer>
                {product &&
                  product.otherProducts &&
                  product.otherProducts.length !== 0 && (
                    <OtherProductsCarousel
                      otherProducts={product.otherProducts}
                    />
                  )}
              </DetailContainer>
            </>
          ) : (
            <SkeletonContainer></SkeletonContainer>
          )}
          {/* 구매하기 버튼 클릭 시 상세 내용 */}
          {showBottomSheet && (
            <BottomSheetDefault
              callback={handleSendInfoToAmplitudeAndFacebookPixel}
              closeBottomSheet={onClickBottomSheetClose}
              product={product}
              totalPrice={product.priceFinal}
              uidFromApp={uidFromApp}
            />
          )}
        </>
      )}
    </GlobalContainer>
  );
};

const ShareLoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeadLineWrapper = styled.div`
  margin-top: 0.5rem;
`;
const loading = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.04);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.08);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const animation = css`
  animation: ${loading} 1s linear infinite;
`;

const ContentsTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FixedContainer = styled.div`
  position: relative;
  max-width: 40rem;
  width: 100%;
  height: 4.375rem; // 70px
`;

const HeaderContainer = styled.div`
  max-width: 40rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4.375rem; // 70px

  color: ${palette.neutral007};

  ${(props) =>
    props.isFavApp &&
    css`
      position: fixed;
      z-index: 1000;
      background-color: ${palette.white};
    `};
`;
const HeaderTitle = styled.div`
  /* H_PageTitle-20 */
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
`;

const ImageContainer = styled.div`
  ${animation};
  width: 100%;
  // aspect-ratio: 5 / 4;
  aspect-ratio: 1 / 1; // FIXMEFIXME 이미지 비율
  object-fit: cover;
  @media screen and (max-width: 500px) {
    ${animation};
  }
  margin-bottom: 40px;
  @media screen and (max-width: 500px) {
    margin-bottom: 20px;
  }
`;
const ContentsWrapper = styled.div`
  padding: 0 1.563rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 2.5rem;
  @media screen and (max-width: 500px) {
    margin-top: 1.875rem;
  }
`;
const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &.loading {
    ${animation};
    height: 1.3rem;
    width: 40%;
  }
`;
const CategoryDivider = styled.div`
  height: 1rem;
  margin: 0 0.5rem;
  width: 1px;
  background-color: ${palette.neutral003};
`;

const CategoryText = styled.div`
  color: ${palette.neutral005};

  /* Badge */
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
`;

const TitleContainer = styled.div`
  color: ${palette.neutral007};
  font-family: Pretendard;
  font-size: 1.563rem; // 25px
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem; /* 120% */
  margin-top: 0.75rem;
  &.loading {
    ${animation};
    height: 2rem;
    width: 80%;
  }
`;
const DescContainer = styled.div`
  font-size: 1.125rem; // 18px;
  font-weight: 400;
  line-height: 1.5rem;
  color: ${palette.neutral006};
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  &.loading {
    ${animation};
    height: 1.125rem;
  }
`;

const PriceDiscountContainer = styled.div`
  font-size: 2rem;
  font-weight: 700;
  line-height: 125%; /* 125% */
  display: flex;
  flex-direction: column;
  &.loading {
    ${animation};
    height: 1rem;
  }
`;
const PriceDiscountOriginalPrice = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: ${palette.secondary002};
  &.loading {
    ${animation};
    height: 1.125rem;
  }
`;
const PriceDiscountedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.25rem;
`;
const PriceDiscountRate = styled.div`
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  color: #0097ff;
`;
const PriceDiscountedPrice = styled.div`
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  margin-left: 5px;
  color: ${palette.fontBlack};

  &.loading {
    ${animation};
    height: 2rem;
  }
`;
const ProductTypeLabel = styled.div`
  background-color: #00000066;
  color: white;
  display: inline-block;
  padding: 4px 12px;
  white-space: nowrap;
  margin-right: 6px;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.5rem;
  &.loading {
    background-color: transparent;
  }
`;

const ExpandButtonArea = styled.div`
  padding-bottom: 30px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 300px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  display: flex;
  justify-content: center;
  align-items: end;
`;
const ExpandButton = styled.div`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 48px;
  border: 1px solid #000;
  border-radius: 10px;
  cursor: pointer;
  width: calc(100% - 50px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 쉐도우 추가 */

  color: ${palette.neutral005};
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
  background-color: ${palette.white};
`;

const DetailContainer = styled.div``;
const ProductWrapper = styled.div``;

const SkeletonContainer = styled.div`
  ${animation};
  height: 18px;
  margin-top: 8px;
  width: calc(100% - 3.125rem);
  margin: 8px auto 0 auto;
`;
const Notice = styled.div`
  background-color: #000000;
  padding: 19px 0;
  text-align: center;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  @media screen and (max-width: 500px) {
    border-radius: 0;
  }
`;
const NoticeWrapper = styled.div`
  margin-top: 3.75rem;
  margin-bottom: 2.5rem;
`;

const Highlight = styled.span`
  color: #0097ff;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
`;

const ContentsImageContainer = styled.div`
  position: relative;
  height: ${(props) => (props.expand ? '100%' : '70vh')};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
  padding: 0;
  margin-top: 2.5rem;
`;

const ContentsDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
  padding: 0 1.5625rem;
  margin-top: 2.5rem;
  &.notice {
    padding-bottom: 3.75rem;
  }
`;
const DescTitle = styled.div`
  color: ${palette.neutral007};
  ${H_SpaceName};
  margin-bottom: 10px;
`;
const DescImageWrapper = styled.img``;
const StickyContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;
const StickyBar = styled.div`
  position: ${({ isSticky }) => (isSticky ? 'fixed' : 'relative')};
  top: ${({ isSticky }) => (isSticky ? '0' : 'auto')};
  width: 100%;
  max-width: 40rem;
  background-color: white;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-around;
  left: ${({ isSticky }) => (isSticky ? 'calc(50% - 20rem)' : '0')};
  @media screen and (max-width: 40rem) {
    left: 0;
  }
`;
const TabButton = styled.div`
  background: none;
  color: ${palette.neutral007};
  border: none;
  flex: 1;
  padding: 11px 0;
  margin: 0 10px;
  cursor: pointer;
  ${HPageTitle20};
  font-weight: 500;
  text-align: center;
  border-bottom: ${({ active }) => (active ? '2px solid #0097FF' : 'none')};
`;

const ContentsText = styled.div`
  margin-top: 0.625rem;
  color: ${palette.neutral006};

  p {
    ${Body_Text_16};
    font-weight: 400;
  }
  strong {
    font-weight: 700;
  }
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1.0625rem;
  }
`;

const SubTitle = styled.div`
  color: ${palette.neutral006};
  ${Body_Text_16};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0.5rem')};
`;

const WrongProduct = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 0.625rem;
`;
const IconContainerRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 0.625rem;
`;
const IconWrapper = styled.img`
  cursor: pointer;
  padding: 13px;
`;

const AlphaHeaderContainer = styled.div`
  width: 100%;
  height: 3.125rem; // 50px;
  position: absolute;
  top: 3.75rem; // 60px;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FloatButtonWrapper = styled.img`
  // padding: 0.813rem; // 13px;
  aspect-ratio: 1 / 1;
  padding: 0.813rem;
  border-radius: 20px;
  background-color: ${palette.opacity008};
  margin: 0 0.625rem; // 10px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.opacity006};
  }
`;

export default ProductDetailContainer;
