import styled from 'styled-components';
import GlobalContainer from './GlobalContainer';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  Body_Text_16,
  Button,
  HPageTitle25,
  SubTitle,
  TextButton,
  palette,
} from 'modules/defines/styles';
import React, { Suspense, useEffect, useState } from 'react';
import { userAPI } from 'api';
import {
  ClusteredOption,
  PurchaseItem,
  PurchaseItemBundle,
} from 'modules/defines/interfaces';
import { Player } from '@lottiefiles/react-lottie-player';
import { spinner } from 'assets/lottie';
import TicketItem from 'components/PurchaseTicket/TicketItem';
import { InputModal } from 'components/common/Modal';
import { encodeToBase64, decodeFromBase64 } from 'modules/defines/encrypt';
import { UserAgent } from 'utils/userAgent';
import AppBanner from 'components/common/AppBanner';
import Header from 'components/common/Header';
import { SafeAreaInAppAndroid } from 'components/common/Common';

const PurchaseTicketContainer = () => {
  const { os, browser } = UserAgent;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { oid: orderIdNumber } = useParams();
  const uidFromApp = searchParams.get('uid'); // 앱에서는 해당 uid로 작동하도록, 웹에서는 null
  const [purchaseItem, setPurchaseItem] = useState<PurchaseItemBundle>(); // 여러개 옵션 중 하나만 불러와서 대표되는 정보들을 표기
  const [purchaseItems, setPurchaseItems] = useState<PurchaseItem[]>([]);
  const [clusteredOption, setClusteredOption] = useState<ClusteredOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [exceptionMessage, setExceptionMessage] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const TicketItemLazy = React.lazy(
    () => import('components/PurchaseTicket/TicketItem')
  );
  useEffect(() => {
    if (clusteredOption) {
      console.log(clusteredOption);
    }
  }, [clusteredOption]);

  const handleModalConfirm = (name: string, phone: string) => {
    const headers = {
      phone: encodeToBase64(phone),
      name: encodeToBase64(name),
    };
    if (orderIdNumber) {
      userAPI.purchaseInfoAnonymous(orderIdNumber, headers).then((res) => {
        if (res.data.message === 'OK') {
          setPurchaseItem(res.data.representItem);
          setPurchaseItems(res.data.userPurchaseItems);
          clusterOption(res.data.userPurchaseItems).then((result) => {
            setClusteredOption(result);
          });
        } else if (res.data.message === 'ORDER_NOT_FOUND') {
          setExceptionMessage('주문 정보가 확인되지 않습니다.');
          alert(
            '주문 정보가 확인되지 않습니다.\n주문자 정보를 다시 확인해 주세요.'
          );
        }
      });
    } else {
      setExceptionMessage('잘못된 접근입니다.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (uidFromApp) {
      const headers = {
        id: decodeFromBase64(uidFromApp),
      };
      if (orderIdNumber) {
        userAPI.purchaseInfo(orderIdNumber, headers).then((res) => {
          if (res.data.message === 'OK') {
            setPurchaseItem(res.data.representItem);
            setPurchaseItems(res.data.userPurchaseItems);
            clusterOption(res.data.userPurchaseItems).then((result) => {
              setClusteredOption(result);
            });
          } else if (res.data.message === 'USER_NOT_FOUND') {
            setExceptionMessage('유저 정보가 확인되지 않습니다');
          }
        });
      } else {
        setExceptionMessage('잘못된 접근입니다.');
        setIsLoading(false);
      }
    } else {
      setIsModalOpen(true);
    }
  }, []);
  // setState Delay
  useEffect(() => {
    if (purchaseItem && purchaseItems && clusteredOption) {
      setIsLoading(false);
    }
  }, [purchaseItem, purchaseItems, clusteredOption]);

  const clusterOption = async (items: PurchaseItem[]) => {
    var clusteredOpt: ClusteredOption[] = [];
    await Promise.all(
      items.map((item) => {
        if (item.option) {
          const found = clusteredOpt.find(
            (cluster) => cluster.title === item.option.title
          );
          if (found) {
            found.count += 1;
          } else {
            clusteredOpt.push({
              title: item.option.title,
              price: item.option.price,
              count: 1,
            });
          }
        }
      })
    );
    return clusteredOpt;
  };

  const convertDate = (date: string) => {
    const ymd = date.split('T')[0];
    return (
      ymd.split('-')[0] +
      '년 ' +
      ymd.split('-')[1] +
      '월 ' +
      ymd.split('-')[2] +
      '일까지'
    );
  };

  return (
    <GlobalContainer padding={false}>
      {os.isAndroid && browser.isFavApp && <SafeAreaInAppAndroid />}
      <AppBanner os={os} browser={browser} isFixed={false} />
      <Header isFavApp={browser.isFavApp} title={''} />
      <SectionContainer>
        <HeaderText>상품 확인</HeaderText>
        <HeaderDivider />
        {isLoading ? (
          <>
            <Player
              autoplay
              loop
              src={spinner}
              style={{
                width: '48px',
                height: '48px',
              }}
            />
            {isModalOpen && (
              <InputModal
                onConfirm={handleModalConfirm}
                isPadding={false}
                isShowCloseButton={false}
                onCloseButton={() => {}}
              />
            )}
          </>
        ) : (
          <ContentsContainer>
            <SpaceTitle>{purchaseItem?.spaceTitle}</SpaceTitle>
            <OrderName>{purchaseItem?.orderName}</OrderName>
            <OrderInfo>
              <OrderInfoRow>
                <OrderInfoKey>주문 ID</OrderInfoKey>
                <OrderInfoValue>{purchaseItem?.orderIdNumber}</OrderInfoValue>
              </OrderInfoRow>
              <OrderInfoRow>
                <OrderInfoKey>옵션</OrderInfoKey>
                <OrderInfoValue>
                  {purchaseItem?.option === null
                    ? `${purchaseItem?.orderName}(${purchaseItems.length})`
                    : clusteredOption.map((cluster, idx) => {
                        return (
                          <>
                            {cluster.title}({cluster.count}){' '}
                          </>
                        );
                      })}
                </OrderInfoValue>
              </OrderInfoRow>
              <OrderInfoRow>
                <OrderInfoKey>수량</OrderInfoKey>
                <OrderInfoValue>{purchaseItems?.length}개</OrderInfoValue>
              </OrderInfoRow>
              <OrderInfoRow>
                <OrderInfoKey>유효 기간</OrderInfoKey>
                <OrderInfoValue style={{ color: palette.primary }}>
                  {purchaseItem?.expireAt
                    ? convertDate(purchaseItem?.expireAt)
                    : '없음'}
                </OrderInfoValue>
              </OrderInfoRow>
            </OrderInfo>
            <TicketItemWrapper>
              <TicketItem
                idx={-1}
                sTitle={purchaseItem?.spaceTitle || ''}
                orderItem={purchaseItems[0]}
                count={
                  purchaseItems.filter((item) => item.usedAt === null).length
                }
              />
              {purchaseItems.map((pItem, idx) => {
                return (
                  <Suspense
                    fallback={<LoadingContainer />}
                    key={`purchase-item-${idx}`}
                  >
                    <TicketItemLazy
                      idx={idx}
                      sTitle={purchaseItem?.spaceTitle || ''}
                      orderItem={pItem}
                      count={1}
                    />
                  </Suspense>
                );
              })}
            </TicketItemWrapper>
            <DescWrapper>
              <DescText>
                <Divider />
                <HeaderText>유의 사항</HeaderText>
                <DescParagraph>
                  매장에서 상품 수령을 확인하기 위한 화면입니다.
                  <br /> 기한 만료 또는 수령 확인이 되면 환불이 불가합니다.{' '}
                  <br />
                </DescParagraph>
                <ul>
                  <DescListItem>
                    상품 사용 시 해당 QR 코드를 제시해주세요.
                  </DescListItem>
                  <DescListItem>
                    여러 개 구입한 상품을 한 번에 사용할 때는&nbsp;
                    <Strong>한번에 확인</Strong>&nbsp; 항목을 눌러 제시해주세요.
                  </DescListItem>
                </ul>
              </DescText>
            </DescWrapper>
            <ButtonInstant type="button" onClick={() => navigate(-1)}>
              확인
            </ButtonInstant>
          </ContentsContainer>
        )}
      </SectionContainer>
    </GlobalContainer>
  );
};

const ButtonInstant = styled.button`
  ${Button};
  color: white;
  margin: 3.75rem auto 2.125rem auto;
  width: 100%;
  border-radius: 10px;
  padding: 1.25rem 0;
  background: ${palette.neutral007};
  &:active {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.3) 100%
      ),
      #1e1e1e;
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${palette.neutral003};
  margin-bottom: 1.88rem;
`;

const DescParagraph = styled.p`
  ${Body_Text_16};
  font-weight: 400;
  margin-top: 0.9375rem;
  margin-bottom: 0.25rem;
`;

const Strong = styled.strong`
  font-weight: 700;
  color: ${palette.neutral007};
`;

const DescListItem = styled.li`
  list-style-type: disc;
  margin-left: 1.3125rem;
  color: ${palette.neutral005};
  ${Body_Text_16};
  font-weight: 400;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  background: ${palette.neutral002};
`;

const SectionContainer = styled.div`
  margin-top: 1.5625rem;
  display: flex;
  flex-direction: column;
  padding: 0rem 25px;
  height: 100%;
  min-height: 100vh;
`;

const HeaderText = styled.div`
  color: ${palette.neutral007};
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem; /* 120% */
`;
const HeaderDivider = styled.div`
  background: ${palette.neutral005};
  height: 1px;
  align-self: stretch;
  margin-top: 1rem;
  margin-bottom: 1.8125rem;
`;
const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 7.5rem;
`;
const SpaceTitle = styled.div`
  color: ${palette.neutral007};
  ${SubTitle};
`;
const OrderName = styled.div`
  margin-top: 0.25rem;
  ${palette.neutral007};
  ${HPageTitle25};
`;
const OrderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
`;
const OrderInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  color: ${palette.neutral007};
  ${TextButton};
`;
const OrderInfoKey = styled.div`
  font-weight: 700;
  width: 60px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const OrderInfoValue = styled.div`
  font-weight: 400;
`;
const TicketItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const DescWrapper = styled.div`
  display: flex;
  margin-top: 1.875rem;
  width: 100%;
`;
const DescText = styled.div`
  font-size: 0.875rem;

  font-weight: 400;
  line-height: 1.25rem;
  color: ${palette.neutral005};
  width: 100%;
`;

export default PurchaseTicketContainer;
