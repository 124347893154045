import { palette } from 'modules/defines/styles';
import { icon_back, icon_receipt } from 'assets/icon';
import styled, { css } from 'styled-components';
import { UserAgent } from 'utils/userAgent';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  isLoading?: boolean;
  title?: string;
  isFavApp: boolean;
  empty?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  isFavApp,
  isLoading,
  title,
  empty = false,
}) => {
  const navigate = useNavigate();

  return (
    <FixedContainer isFavApp={isFavApp}>
      {!empty && (
        <HeaderContainer isFavApp={isFavApp}>
          <IconContainer>
            <IconWrapper
              onClick={() => navigate(-1)}
              src={icon_back}
              alt="icon_back"
            />
          </IconContainer>
          {!isLoading ? <>{title}</> : <></>}
        </HeaderContainer>
      )}
    </FixedContainer>
  );
};

const FixedContainer = styled.div<{ isFavApp: boolean }>`
  position: relative;
  max-width: 40rem;
  width: 100%;
  height: 4.375rem; // 70px
  ${(props) =>
    props.isFavApp &&
    css`
      margin-bottom: 0.938rem;
    `}
`;

const HeaderContainer = styled.div<{ isFavApp: boolean }>`
  max-width: 40rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 4.375rem; // 70px

  color: ${palette.neutral007};

  /* H_PageTitle-20 */
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  ${(props) =>
    props.isFavApp &&
    css`
      position: fixed;
      z-index: 1000;
      background-color: ${palette.white};
    `}
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  left: 0.625rem;
`;
const IconWrapper = styled.img`
  cursor: pointer;
  padding: 13px;
`;

export default Header;
