import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
export const facebookPixelInit = () => {
  if (!process.env.REACT_APP_LOCAL) {
    ReactPixel.init('1601192133774171');
  }
};

export const useFacebookPixelPageView = () => {
  useEffect(() => {
    if (!process.env.REACT_APP_LOCAL) {
      ReactPixel.pageView();
    }
  }, []);
};

export const facebookPixelTrack = (eventName, data) => {
  if (!process.env.REACT_APP_LOCAL) {
    ReactPixel.trackCustom(eventName, data);
  }
};

export const facebookPixelViewMessage = () => {
  if (!process.env.REACT_APP_LOCAL) {
    ReactPixel.trackCustom('ViewContent', {
      contents: 'Pageview에서 paid_result Page로 이동',
    });
  }
};
