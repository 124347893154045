import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import { SpaceInfoHeader } from 'components/common/SpaceCommon';
import SpaceMap from './SpaceMap';
import LinkIcon from 'assets/icon/link.png';
import PhoneIcon from 'assets/icon/phone.png';
import ProductInfo from 'components/ProductDetail/ProductInfo';

const SpaceInfo = ({ space }) => {
  return (
    <>
      <SpaceInfoHeader>공간 정보</SpaceInfoHeader>
      <SpaceMap
        title={space.title}
        latitude={space.address_lat}
        longitude={space.address_long}
        category={space.category_string[0]}
      />
      <SpaceAddressRow>
        <ProductInfo spaceTitle={''} spaceAddress={space.address} />
      </SpaceAddressRow>
      <SpaceInfoSection>
        <SpaceInfoSectionTitle marginBottom={'16px'}>
          영업시간
        </SpaceInfoSectionTitle>
        {space.time.map((time, idx) => {
          return (
            <SpaceInfoText
              marginBottom={idx + 1 === space.time.length ? '0px' : '5px'}
            >
              {time}
            </SpaceInfoText>
          );
        })}
      </SpaceInfoSection>
      {space.menuImages.length !== 0 && (
        <SpaceInfoSection>
          <SpaceInfoSectionTitle marginBottom={'20px'}>
            메뉴
          </SpaceInfoSectionTitle>
          <SpaceInfoMenuImage src={space.menuImages[0]} key={`menu-img`} />
        </SpaceInfoSection>
      )}
      <SpaceInfoSection>
        <SpaceInfoSectionTitle marginBottom={'20px'}>
          제공 서비스
        </SpaceInfoSectionTitle>

        <SpaceInfoFilterRow>
          {space.filters.map((filter) => {
            return <SpaceFilterText>{filter.title}</SpaceFilterText>;
          })}
        </SpaceInfoFilterRow>
      </SpaceInfoSection>
      <SpaceInfoButtonSection>
        <SpaceInfoSectionTitle>전화번호</SpaceInfoSectionTitle>
        <SpaceInfoButton href={`tel:${space.phone}`}>
          <SpaceInfoButtonIcon />
          <SpaceInfoButtonText>전화하기</SpaceInfoButtonText>
        </SpaceInfoButton>
      </SpaceInfoButtonSection>

      <SpaceInfoButtonSection>
        <SpaceInfoSectionTitle>홈페이지</SpaceInfoSectionTitle>
        <SpaceInfoButton href={space.link}>
          <SpaceInfoButtonIcon link />
          <SpaceInfoButtonText>바로가기</SpaceInfoButtonText>
        </SpaceInfoButton>
      </SpaceInfoButtonSection>
    </>
  );
};

const SpaceAddressRow = styled.div`
  margin: 20px 25px 30px;
`;

const SpaceInfoSection = styled.div`
  margin: 0 25px 30px 25px;
`;
const SpaceInfoButtonSection = styled.div`
  margin: 0 25px 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SpaceInfoSectionTitle = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: ${palette.neutral007};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
`;
const SpaceInfoFilterRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const SpaceFilterText = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: -0.02rem;
  color: ${palette.neutral007};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  &::after {
    width: 0.01px;
    height: 10px;
    color: ${palette.neutral003};
    content: '|';
    margin: 0 8px;
  }

  &:last-child::after {
    content: '';
  }
`;

const SpaceInfoText = styled.div`
  white-space: pre-wrap;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: -0.02rem;
  color: ${palette.neutral007};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
`;

const SpaceInfoButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${palette.neutral001};
  border-radius: 100px;
  padding: 8px 12px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;
const SpaceInfoButtonIcon = styled.div`
  margin-right: 2px;
  width: 18px;
  height: 18px;
  background-image: url(${(props) => (props.link ? LinkIcon : PhoneIcon)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
const SpaceInfoMenuImage = styled.img`
  width: 100%;
  height: 125px;
  object-fit: cover;
  border-radius: 15px;
`;

const SpaceInfoButtonText = styled.div`
  display: flex;
  align-items: center;
  color: ${palette.neutral005};
`;

export default SpaceInfo;
