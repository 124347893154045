import { createGlobalStyle, css } from 'styled-components';
import reset from 'styled-reset';

export const palette = {
  mainBlueLight: '#B3E0FF',
  mainBlue: '#0097FF',
  mainBlueHover: '#0067AD',
  white: '#FFFFFF',

  fontBlack: '#1e1e1e',
  fontDarkGray: '#323232',
  fontGray: '#7a7a7a',
  fontBlue: '#0097FF',
  fontLightGray: '#AEAEAE',
  fontWhite: '#FFFFFF',
  fontWarning: '#DC3545',

  backgroundGray: '#EAEAEA',
  backgroundDarkGray: '#323232',
  backgroundOverlay: 'rgba(0, 0, 0, 0.4)',

  borderWhite: 'rgba(255, 255, 255, 0.4)',
  borderLightGray: '#e6e6e6',
  borderGray: '#7a7a7a',
  ///////////////////////////////////
  // Component
  neutral001: '#F4F4F4',
  neutral002: '#EFEFEF',
  neutral003: '#D9D9D9', // border
  neutral004: '#979797',
  neutral005: '#717171',
  neutral006: '#393939',
  neutral007: '#1e1e1e',
  neutral008: '#000A1E',

  primary: '#0097FF',
  primaryHover: '#4DB6FF',
  secondaryHover: '#626262',
  secondary002: '#C1C0C9',

  accent: '#EA4D5D',
  highlight: '#EB2323',
  danger: '#E31D1C',
  opacity006: 'rgba(255, 255, 255, 0.30)', // hover
  opacity008: 'rgba(30, 30, 30, 0.40)',
};

const GlobalStyles = createGlobalStyle`
  ${reset};
  html {
    height: 100%;
    overflow-x: auto;
  }
  button {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
  }
`;

export const HPageTitle20 = css`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem; /* 120% */
`;
export const HPageTitle25 = css`
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 1.875rem;
`;
export const SubTitle = css`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
`;

export const Button = css`
  font-size: 1.0625rem;
  font-weight: 700;
  line-height: 1.25rem; /* 117.647% */
`;

export const TextButton = css`
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
`;

export const Badge = css`
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.5rem; /* 160% */
`;

export const BodySubmenu = css`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem; /* 125% */
`;

export const Caption = css`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
`;

export const TabTitle = css`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: normal;
`;
export const TagName = css`
  font-size: 0.9375rem;
  font-weight: 100;
  line-height: 1.375rem;
`;
export const TitleAll = css`
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem; /* 133.333% */
`;

export const SubChip = css`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 117.647% */
`;

export const H_SpaceName = css`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
`;

export const Body_Text_16 = css`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.02rem;
`;

export default GlobalStyles;
