import styled, { css, keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.04);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.08);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
export const LoadingSkeleton = styled.div<{
  width?: string;
  height?: string;
  type?: string;
  marginTop?: string;
  marginBottom?: string;
}>`
  animation: ${loading} 1s linear infinite;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};

  ${(props) =>
    props.type === 'main-space' &&
    css`
      width: 100%;
      aspect-ratio: 5 / 4;
    `}

  ${(props) =>
    props.type === 'product-img' &&
    css`
      width: 100%;
      aspect-ratio: 1;
    `}
`;
interface LoadingProductListSkeletonProps {
  count: number;
}

export const LoadingProductListSkeleton: React.FC<
  LoadingProductListSkeletonProps
> = ({ count }) => {
  return (
    <ProductListContainer>
      {[...Array(count)].map((_, index) => (
        <ColumnContainer>
          <ImageContainer>
            <LoadingSkeleton type={'product-img'} />
          </ImageContainer>

          <ProductInfoContainer>
            <LoadingSkeleton width={'40%'} height={'1rem'} />
            <LoadingSkeleton
              width={'90%'}
              height={'1.2rem'}
              marginTop={'8px'}
            />
            <LoadingSkeleton
              width={'60%'}
              height={'1.2rem'}
              marginTop={'6px'}
            />
          </ProductInfoContainer>
        </ColumnContainer>
      ))}
    </ProductListContainer>
  );
};

const ProductListContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  column-gap: 0.125rem;
  row-gap: 1.25rem;
  @media screen and (max-width: 620px) {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 92%;
  padding: 0.625rem 0.938rem 0.625rem 0.938rem;
`;
