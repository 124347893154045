import { HPageTitle25, palette } from 'modules/defines/styles';
import styled, { css } from 'styled-components';

export const ContentsContainer = styled.div``;

export const HeaderWrapper = styled.div`
  margin-top: 2.5rem;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const IconWrapper = styled.div`
  padding: 0.8125rem;
  margin-right: 0.625rem;
`;
export const ContentsWrapper = styled.div<{
  marginTop?: string;
  align?: string;
}>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0rem')};
  padding: 0rem 25px;
  text-align: left;

  ${(props) =>
    props.align == 'center' &&
    css`
      text-align: center;
    `}
  ${(props) =>
    props.align == 'left' &&
    css`
      text-align: left;
    `}
`;

export const PageTitle = styled.div`
  color: ${palette.neutral007};
  ${HPageTitle25};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 25px;
  flex-direction: column;
  display: flex;
  position: absolute;
  bottom: 20px;
  gap: 10px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  width: calc(100% - 50px);
  max-width: 450px;
`;
