import { Player } from '@lottiefiles/react-lottie-player';
import { spinner } from 'assets/lottie';
import { palette } from 'modules/defines/styles';
import styled from 'styled-components';

interface LoadingPage {
  content?: string;
}

const LoadingPage: React.FC<LoadingPage> = (props) => {
  const { content } = props;
  return (
    <WaitingWrapper>
      <Player
        style={{
          width: '48px',
          height: '48px',
        }}
        id="spinner_player"
        src={spinner}
        autoplay
        loop
      />
      <WaitingText>{content ? content : '잠시만 기다려주세요'}</WaitingText>
    </WaitingWrapper>
  );
};

const WaitingWrapper = styled.div`
  background-color: ${palette.white};
  display: block;
  text-align: center;
  padding-top: 120px;
  width: 100%;
  height: 100%;
`;

const WaitingText = styled.p`
  color: ${palette.neutral005};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem; /* 137.5% */
  word-break: break-all;
`;

export default LoadingPage;
