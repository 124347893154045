import { palette } from 'modules/defines/styles';
import { useNavigate } from 'react-router';
import { pagePaths } from 'modules/defines/paths';
import styled from 'styled-components';
import { LoadingSkeleton } from './LoadingSkeleton';

interface ProductItemProps {
  productId: string;
  category: string;
  space_title: string;
  title: string;
  discount: number;
  price: number;
  img: string;
  type: string; // 'main' | 'space'
  width?: string;
}

const ProductItem: React.FC<ProductItemProps> = ({
  productId,
  category,
  space_title,
  title,
  discount,
  price,
  img,
  type,
  width = '100%', // width 지정이 필요할 때
}) => {
  const navigate = useNavigate();

  const handleClick = (pid: string) => {
    window.location.href = pagePaths.product.replace(':pid', pid.toString());
    // navigate(pagePaths.product.replace(':pid', pid.toString()));
  };

  var textWidth: string = width;
  if (width !== '100%') {
    textWidth = Number(width.split('px')[0]) - 30 + 'px';
  }

  return (
    <Container onClick={() => handleClick(productId)}>
      <ImageContainer>
        <CategoryText>{category}</CategoryText>
        <ProductImage src={img} width={width} />
      </ImageContainer>

      <ProductInfoContainer width={textWidth}>
        {type === 'main' && (
          <ProductInfoSpaceTitle>{space_title}</ProductInfoSpaceTitle>
        )}
        {type === 'main' && <ProductInfoTitle>{title}</ProductInfoTitle>}
        <ProductInfoPriceWrapper type={type}>
          <ProductInfoDiscount>{Math.round(discount)}%</ProductInfoDiscount>
          {Number(price).toLocaleString()}
          <ProductInfoPrice> 원</ProductInfoPrice>
        </ProductInfoPriceWrapper>
        {type === 'space' && <ProductInfoTitle>{title}</ProductInfoTitle>}
      </ProductInfoContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`;

const CategoryText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: ${palette.fontWhite};

  /* Badge */
  font-family: Pretendard;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 160% */

  background: rgba(0, 0, 0, 0.4);
  display: flex;
  padding: 0.25rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
`;

const ProductImage = styled.img<{ width: string }>`
  object-fit: cover;
  width: ${(props) => props.width};
  aspect-ratio: 1;
`;

const ProductInfoContainer = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 0.625rem 0.938rem 0.625rem 0.938rem;
`;
const ProductInfoSpaceTitle = styled.div`
  color: ${palette.neutral005};
  font-family: Pretendard;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem; /* 133.333% */
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ProductInfoTitle = styled.div`
  color: ${palette.fontBlack};

  /* Body_Submenu */
  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 125% */

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 최대 두 줄까지만 표시 */
  white-space: normal; /* 자동 줄바꿈 */
  text-overflow: ellipsis; /* 넘치는 부분은 ... 처리 */
  width: 92%;
  margin-top: 0.25rem;
`;

const ProductInfoPriceWrapper = styled.div<{ type: string }>`
  display: flex;
  flex-direction: row;
  color: ${palette.fontBlack};

  font-family: Pretendard;
  font-size: 1.063rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem; /* 117.647% */
  align-items: center;
  ${(props) => props.type === 'main' && 'margin-top: 0.375rem'};
`;

const ProductInfoDiscount = styled.div`
  color: ${palette.highlight};
  margin-right: 0.25rem;
`;
const ProductInfoPrice = styled.div`
  font-feature-settings:
    'liga' off,
    'clig' off;
  color: ${palette.fontBlack};

  /* Body_Submenu */
  font-family: Pretendard;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 125% */
  margin-left: 0.125rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ProductItem;
