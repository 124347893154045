import styled from 'styled-components';
import { SpaceTitleLabel } from 'components/common/SpaceCommon';
import { palette } from 'modules/defines/styles';

const SpaceFAVContents = ({ space }) => {
  return (
    <>
      <SpaceWrapper>
        <SpaceImageWrapper src={space.img} key={`space-img-represent`} />
      </SpaceWrapper>
      <SpaceHeaderRow>
        <SpaceTitleLabel marginBottom={'4px'}>{space.title}</SpaceTitleLabel>
        <SpaceDescription>{space.description}</SpaceDescription>
        <SpaceDescription2>{space.description2}</SpaceDescription2>
      </SpaceHeaderRow>
    </>
  );
};

const SpaceWrapper = styled.div``;
const SpaceImageWrapper = styled.img`
  width: 100%;
  height: 428px;
  object-fit: cover;
  margin-bottom: 40px;
`;
const SpaceHeaderRow = styled.div`
  margin: 0 30px 60px 30px;
`;

const SpaceDescription = styled.div`
  font-size: 2rem;
  font-weight: 300;
  line-height: 2.5rem;
  margin-bottom: 25px;
  color: ${palette.neutral006};
`;
const SpaceDescription2 = styled.div`
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.646875rem;
  letter-spacing: -0.02rem;
  color: ${palette.neutral006};
`;

export default SpaceFAVContents;
