import styled, { css, keyframes } from 'styled-components';
import { palette } from 'modules/defines/styles';

const loading = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.04);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.08);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const animation = css`
  animation: ${loading} 1s linear infinite;
`;

export const SpaceInfoHeader = styled.div`
  margin: 30px 25px;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: ${palette.neutral007};
`;

export const SpaceTitleLabel = styled.div`
  color: ${palette.neutral006};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '10px'};
  display: block;
  max-width: 322px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  color: ${palette.neutral006};

  /* H_SpaceName */
  font-family: Pretendard;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;

  &.loading {
    ${animation};
    height: 1.5625rem;
  }
`;
