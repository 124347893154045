import styled, { css } from 'styled-components';
import GlobalContainer from './GlobalContainer';
import { productAPI } from 'api';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import ProductItem from 'components/common/ProductItem';
import AppBanner from 'components/common/AppBanner';
import { UserAgent } from 'utils/userAgent';
import { palette } from 'modules/defines/styles';
import { icon_back } from 'assets/icon';
import { useNavigate } from 'react-router-dom';
import { LoadingProductListSkeleton } from 'components/common/LoadingSkeleton';
import Header from 'components/common/Header';
import { viewPageLog } from 'utils/amplitude';
import { SafeAreaInAppAndroid } from 'components/common/Common';

interface ProductData {
  id: number;
  productId: string;
  category: string;
  title: string;
  img: string;
  brand: string;
  discount: number;
  price: number;
  priceFinal: number;
  img_webp: string;
}

const SpaceContainer = () => {
  const { os, browser } = UserAgent;
  const { sid: spaceId } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState<ProductData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    viewPageLog('space', spaceId); //amplitude tracking
    productAPI.getProductSpace(spaceId || '0').then((res) => {
      setProducts(res.data);
    });
  }, []);
  useEffect(() => {
    if (products.length > 0) {
      console.log(products);
      setIsLoading(false);
    }
  }, [products]);
  return (
    <GlobalContainer padding={false}>
      {os.isAndroid && browser.isFavApp && <SafeAreaInAppAndroid />}
      <AppBanner os={os} browser={browser} isFixed={false} />
      <Header
        isFavApp={browser.isFavApp}
        title={products[0] !== undefined ? products[0].brand : ''}
      />

      {/* <FixedContainer isFavApp={browser.isFavApp}>
        <HeaderContainer isFavApp={browser.isFavApp}>
          <IconContainer>
            <IconWrapper
              onClick={() => navigate(-1)}
              src={icon_back}
              alt="icon_back"
            />
          </IconContainer>
          {!isLoading ? <>{products[0].brand}</> : <></>}
        </HeaderContainer>
      </FixedContainer> */}
      {!isLoading ? (
        <ProductListContainer>
          {products.map((product, idx) => {
            return (
              <ProductItem
                key={`${product.id}_${idx}`}
                productId={product.productId}
                category={product.category}
                space_title={product.brand}
                title={product.title}
                discount={product.discount}
                price={product.priceFinal}
                img={product.img_webp}
                type={'space'}
              />
            );
          })}
        </ProductListContainer>
      ) : (
        <LoadingProductListSkeleton count={3} />
      )}
    </GlobalContainer>
  );
};

const ProductListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  column-gap: 0.125rem;
  row-gap: 1.25rem;
  @media screen and (max-width: 620px) {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
  }
`;
export default SpaceContainer;
