import styled from 'styled-components';
import GlobalContainer from '../GlobalContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BodySubmenu, palette } from 'modules/defines/styles';
import { useEffect, useState } from 'react';
import LoadingPage from 'components/common/LoadingPage';
import {
  ButtonContainer,
  ContentsContainer,
  ContentsWrapper,
  HeaderWrapper,
  IconWrapper,
  PageTitle,
} from 'components/Payment/Layout';
import { CloseButton } from 'components/ProductDetail/BottomSheetCommon';
import { icon_close_black } from 'assets/icon';
import { PaymentInfo } from 'modules/defines/interfaces';
import { decodeFromBase64 } from 'modules/defines/encrypt';
import { ButtonSolid60, ButtonTextRegular } from 'components/common/Button';

const PaymentFail = () => {
  const [searchParams] = useSearchParams();
  const paymentInfoParams = searchParams.get('p');
  // state value
  const [isLoading, setIsLoading] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | null>(null);
  const [failReason, setFailReason] = useState(
    searchParams.get('message') || ''
  );
  // INITIALIZE
  useEffect(() => {
    if (paymentInfoParams) {
      const decoded = decodeFromBase64(paymentInfoParams);
      const paymentInfoJSON = JSON.parse(decoded);
      setPaymentInfo(paymentInfoJSON);
    }
  }, []);
  const goPurchaseList = (e: any) => {
    e.preventDefault();
    window.location.href = `/purchase-list`;
  };
  const goMain = (e: any) => {
    e.preventDefault();
    window.location.href = `/`;
  };

  return (
    <GlobalContainer>
      <Container>
        {isLoading ? (
          <LoadingPage content="결제 정보를 불러오는중입니다.." />
        ) : (
          <ContentsContainer>
            <HeaderWrapper>
              <IconWrapper>
                <CloseButton
                  src={icon_close_black}
                  onClick={(e) => goMain(e)}
                />
              </IconWrapper>
            </HeaderWrapper>
            <ContentsWrapper marginTop="70px" align="center">
              <PageTitle>
                주문이 정상적으로
                <br />
                완료되지 않았습니다.
              </PageTitle>
              <PageDesc>이용에 불편을 드려 죄송합니다.</PageDesc>
            </ContentsWrapper>
            <FailReasonWrapper>
              <FailReasonRow>
                <FailReasonKey>결제 실패 사유 :</FailReasonKey>
                <FailReasonValue color={palette.accent}>
                  {searchParams.get('message') || '알 수 없음'}
                </FailReasonValue>
              </FailReasonRow>
              <FailReasonRow marginTop="5px">
                <FailReasonKey>상품번호 :</FailReasonKey>
                <FailReasonValue>{paymentInfo?.productId}</FailReasonValue>
              </FailReasonRow>

              <FailReasonText>다시 주문해주시기 바랍니다.</FailReasonText>
            </FailReasonWrapper>
            <ButtonContainer>
              {/* <ButtonTextRegular btype="tertiary" onClick={(e) => goMain(e)}>
                메인으로 이동
              </ButtonTextRegular> */}
              <ButtonSolid60
                btype="secondary"
                onClick={(e) => goPurchaseList(e)}
              >
                구매 목록 확인하기
              </ButtonSolid60>
            </ButtonContainer>
          </ContentsContainer>
        )}
      </Container>
    </GlobalContainer>
  );
};

const Container = styled.div`
  height: 100vh;
`;

const PageDesc = styled.div`
  margin-top: 15px;
  color: ${palette.neutral005};
  text-align: center;
  ${BodySubmenu};
`;

const FailReasonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 25px;
  color: ${palette.neutral005};
  background-color: ${palette.neutral001};
  border-radius: 10px;
  margin-top: 3.125rem;
`;

const FailReasonRow = styled.div<{ marginTop?: string }>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0rem')};
  display: flex;
  flex-direction: row;
  gap: 0.3125rem;
`;
const FailReasonKey = styled.div`
  display: flex;
`;
const FailReasonValue = styled.div<{ color?: string }>`
  color: ${(props) => props.color && props.color};
  display: flex;
`;
const FailReasonText = styled.div`
  margin-top: 0.9375rem;
  padding: 0px 4px;
  color: ${palette.neutral005};
  font-size: 1.125rem;

  font-weight: 400;
  line-height: 1.75rem; /* 155.556% */
`;

export default PaymentFail;
