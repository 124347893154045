import {
  Container as MapDiv,
  NaverMap,
  useNavermaps,
  Marker,
} from 'react-naver-maps';

import ALCOHOL from 'assets/map/alcohol.webp';
import CAFE from 'assets/map/cafe.webp';
import CULTURE from 'assets/map/culture.webp';
import ETC from 'assets/map/etc.webp';
import FOOD from 'assets/map/food.webp';
import SHOP from 'assets/map/shop.webp';
import STAY from 'assets/map/stay.webp';

const SpaceMap = ({ title, latitude, longitude, category }) => {
  const navermaps = useNavermaps();
  return (
    <MapDiv
      style={{
        width: '100%',
        height: '250px',
      }}
    >
      <NaverMap
        defaultCenter={
          new navermaps.LatLng(parseFloat(latitude), parseFloat(longitude))
        }
        disableDoubleClickZoom
        defaultZoom={14}
        draggable={false}
        scrollWheel={false}
        pinchZoom={false}
        keyboardShortcuts={false}
      >
        <Marker
          position={
            new navermaps.LatLng(parseFloat(latitude), parseFloat(longitude))
          }
          icon={{
            anchor: new navermaps.Point(22, 22),
            content: `<div style="position: relative; text-align: center;">
                <img 
                  style="width: 44px; height: 44px;" 
                  src="${iconJson[category]}" 
                  alt="${title}"
                />
                <div 
                  style="
                    position: absolute; 
                    top: 38px; 
                    left: 50%; 
                    transform: translateX(-50%);
                    background-color: rgba(255, 255, 255, 0.8); 
                    padding: 2px 6px; 
                    border-radius: 10px; 
                    font-size: 12px; 
                    font-weight: 500;
                    line-height: 16px;
                    color: #000;
                    white-space: nowrap;
                  ">
                  ${title}
                </div>
              </div>`,
          }}
        />
      </NaverMap>
    </MapDiv>
  );
};

const iconJson = {
  ALCOHOL: ALCOHOL,
  CAFE: CAFE,
  CULTURE: CULTURE,
  'etc.': ETC,
  FOOD: FOOD,
  SHOP: SHOP,
  STAY: STAY,
};

export default SpaceMap;
