import { ButtonTextRegular } from 'components/common/Button';
import { Badge, Body_Text_16, SubTitle, palette } from 'modules/defines/styles';
import styled, { css } from 'styled-components';

interface ProductInfoProps {
  marginTop?: string;
  spaceTitle: string;
  spaceAddress: string;
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  marginTop,
  spaceTitle,
  spaceAddress,
}) => {
  const copyAddressToClipboard = (copyText: string) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          // alert('텍스트가 클립보드에 복사되었습니다!');
          // FIXME : alert 대신 toast message로 변경
          console.log('copied: ', copyText);
        })
        .catch((err) => {
          console.error('클립보드에 복사 실패:', err);
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = copyText;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        // alert('텍스트가 클립보드에 복사되었습니다!');
        // FIXME : alert 대신 toast message로 변경
      } catch (err) {
        console.error('클립보드에 복사 실패:', err);
      }
      document.body.removeChild(textArea);
    }
  };
  return (
    <Container marginTop={marginTop}>
      {spaceTitle !== '' && <SpaceTitle>{spaceTitle}</SpaceTitle>}
      {spaceAddress !== '' && (
        <RowWrapper>
          <SpaceAddress>{spaceAddress}</SpaceAddress>
          <ButtonTextRegular
            btype="primary"
            onClick={() => copyAddressToClipboard(spaceAddress)}
          >
            복사
          </ButtonTextRegular>
        </RowWrapper>
      )}
    </Container>
  );
};
const Container = styled.div<{ marginTop?: string }>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
  display: flex;
  flex-direction: column;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SpaceAddress = styled.div`
  color: ${palette.neutral006};
  ${Badge};
`;

const SpaceTitle = styled.div`
  color: ${palette.neutral006};
  ${SubTitle};
  margin-top: 0.5rem;
`;

export default ProductInfo;
