import React from 'react';
import { palette } from 'modules/defines/styles';
interface IconProps {
  disabled?: boolean;
}

const ArrowUp: React.FC<IconProps> = ({ disabled = false }) => {
  var color = palette.neutral007;
  if (disabled) color = palette.neutral004;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Arrows / arrow-up-1">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7974 11.9949C13.3945 11.9981 13.0055 11.8552 12.7089 11.5951L7.9998 7.34592L3.29175 11.5951C2.99558 11.8555 2.60758 12 2.20483 12C1.80209 12 1.41408 11.8555 1.11792 11.5951C0.975773 11.4704 0.862304 11.319 0.784686 11.1503C0.707069 10.9817 0.666992 10.7995 0.666992 10.6153C0.666992 10.4311 0.707069 10.2489 0.784686 10.0802C0.862304 9.91155 0.975773 9.76009 1.11792 9.63541L6.91341 4.40685C7.20855 4.14534 7.59646 4 7.99928 4C8.4021 4 8.79001 4.14534 9.08515 4.40685L14.8827 9.63041C15.0249 9.75509 15.1383 9.90655 15.216 10.0752C15.2936 10.2439 15.3337 10.4261 15.3337 10.6103C15.3337 10.7945 15.2936 10.9767 15.216 11.1453C15.1383 11.314 15.0249 11.4655 14.8827 11.5901C14.5871 11.8497 14.1992 11.9926 13.7974 11.9899"
            fill="#1E1E1E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7974 11.9949C13.3945 11.9981 13.0055 11.8552 12.7089 11.5951L7.9998 7.34592L3.29175 11.5951C2.99558 11.8555 2.60758 12 2.20483 12C1.80209 12 1.41408 11.8555 1.11792 11.5951C0.975773 11.4704 0.862304 11.319 0.784686 11.1503C0.707069 10.9817 0.666992 10.7995 0.666992 10.6153C0.666992 10.4311 0.707069 10.2489 0.784686 10.0802C0.862304 9.91155 0.975773 9.76009 1.11792 9.63541L6.91341 4.40685C7.20855 4.14534 7.59646 4 7.99928 4C8.4021 4 8.79001 4.14534 9.08515 4.40685L14.8827 9.63041C15.0249 9.75509 15.1383 9.90655 15.216 10.0752C15.2936 10.2439 15.3337 10.4261 15.3337 10.6103C15.3337 10.7945 15.2936 10.9767 15.216 11.1453C15.1383 11.314 15.0249 11.4655 14.8827 11.5901C14.5871 11.8497 14.1992 11.9926 13.7974 11.9899"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7974 11.9949C13.3945 11.9981 13.0055 11.8552 12.7089 11.5951L7.9998 7.34592L3.29175 11.5951C2.99558 11.8555 2.60758 12 2.20483 12C1.80209 12 1.41408 11.8555 1.11792 11.5951C0.975773 11.4704 0.862304 11.319 0.784686 11.1503C0.707069 10.9817 0.666992 10.7995 0.666992 10.6153C0.666992 10.4311 0.707069 10.2489 0.784686 10.0802C0.862304 9.91155 0.975773 9.76009 1.11792 9.63541L6.91341 4.40685C7.20855 4.14534 7.59646 4 7.99928 4C8.4021 4 8.79001 4.14534 9.08515 4.40685L14.8827 9.63041C15.0249 9.75509 15.1383 9.90655 15.216 10.0752C15.2936 10.2439 15.3337 10.4261 15.3337 10.6103C15.3337 10.7945 15.2936 10.9767 15.216 11.1453C15.1383 11.314 15.0249 11.4655 14.8827 11.5901C14.5871 11.8497 14.1992 11.9926 13.7974 11.9899"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7974 11.9949C13.3945 11.9981 13.0055 11.8552 12.7089 11.5951L7.9998 7.34592L3.29175 11.5951C2.99558 11.8555 2.60758 12 2.20483 12C1.80209 12 1.41408 11.8555 1.11792 11.5951C0.975773 11.4704 0.862304 11.319 0.784686 11.1503C0.707069 10.9817 0.666992 10.7995 0.666992 10.6153C0.666992 10.4311 0.707069 10.2489 0.784686 10.0802C0.862304 9.91155 0.975773 9.76009 1.11792 9.63541L6.91341 4.40685C7.20855 4.14534 7.59646 4 7.99928 4C8.4021 4 8.79001 4.14534 9.08515 4.40685L14.8827 9.63041C15.0249 9.75509 15.1383 9.90655 15.216 10.0752C15.2936 10.2439 15.3337 10.4261 15.3337 10.6103C15.3337 10.7945 15.2936 10.9767 15.216 11.1453C15.1383 11.314 15.0249 11.4655 14.8827 11.5901C14.5871 11.8497 14.1992 11.9926 13.7974 11.9899"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7974 11.9949C13.3945 11.9981 13.0055 11.8552 12.7089 11.5951L7.9998 7.34592L3.29175 11.5951C2.99558 11.8555 2.60758 12 2.20483 12C1.80209 12 1.41408 11.8555 1.11792 11.5951C0.975773 11.4704 0.862304 11.319 0.784686 11.1503C0.707069 10.9817 0.666992 10.7995 0.666992 10.6153C0.666992 10.4311 0.707069 10.2489 0.784686 10.0802C0.862304 9.91155 0.975773 9.76009 1.11792 9.63541L6.91341 4.40685C7.20855 4.14534 7.59646 4 7.99928 4C8.4021 4 8.79001 4.14534 9.08515 4.40685L14.8827 9.63041C15.0249 9.75509 15.1383 9.90655 15.216 10.0752C15.2936 10.2439 15.3337 10.4261 15.3337 10.6103C15.3337 10.7945 15.2936 10.9767 15.216 11.1453C15.1383 11.314 15.0249 11.4655 14.8827 11.5901C14.5871 11.8497 14.1992 11.9926 13.7974 11.9899"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7974 11.9949C13.3945 11.9981 13.0055 11.8552 12.7089 11.5951L7.9998 7.34592L3.29175 11.5951C2.99558 11.8555 2.60758 12 2.20483 12C1.80209 12 1.41408 11.8555 1.11792 11.5951C0.975773 11.4704 0.862304 11.319 0.784686 11.1503C0.707069 10.9817 0.666992 10.7995 0.666992 10.6153C0.666992 10.4311 0.707069 10.2489 0.784686 10.0802C0.862304 9.91155 0.975773 9.76009 1.11792 9.63541L6.91341 4.40685C7.20855 4.14534 7.59646 4 7.99928 4C8.4021 4 8.79001 4.14534 9.08515 4.40685L14.8827 9.63041C15.0249 9.75509 15.1383 9.90655 15.216 10.0752C15.2936 10.2439 15.3337 10.4261 15.3337 10.6103C15.3337 10.7945 15.2936 10.9767 15.216 11.1453C15.1383 11.314 15.0249 11.4655 14.8827 11.5901C14.5871 11.8497 14.1992 11.9926 13.7974 11.9899"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7974 11.9949C13.3945 11.9981 13.0055 11.8552 12.7089 11.5951L7.9998 7.34592L3.29175 11.5951C2.99558 11.8555 2.60758 12 2.20483 12C1.80209 12 1.41408 11.8555 1.11792 11.5951C0.975773 11.4704 0.862304 11.319 0.784686 11.1503C0.707069 10.9817 0.666992 10.7995 0.666992 10.6153C0.666992 10.4311 0.707069 10.2489 0.784686 10.0802C0.862304 9.91155 0.975773 9.76009 1.11792 9.63541L6.91341 4.40685C7.20855 4.14534 7.59646 4 7.99928 4C8.4021 4 8.79001 4.14534 9.08515 4.40685L14.8827 9.63041C15.0249 9.75509 15.1383 9.90655 15.216 10.0752C15.2936 10.2439 15.3337 10.4261 15.3337 10.6103C15.3337 10.7945 15.2936 10.9767 15.216 11.1453C15.1383 11.314 15.0249 11.4655 14.8827 11.5901C14.5871 11.8497 14.1992 11.9926 13.7974 11.9899"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7974 11.9949C13.3945 11.9981 13.0055 11.8552 12.7089 11.5951L7.9998 7.34592L3.29175 11.5951C2.99558 11.8555 2.60758 12 2.20483 12C1.80209 12 1.41408 11.8555 1.11792 11.5951C0.975773 11.4704 0.862304 11.319 0.784686 11.1503C0.707069 10.9817 0.666992 10.7995 0.666992 10.6153C0.666992 10.4311 0.707069 10.2489 0.784686 10.0802C0.862304 9.91155 0.975773 9.76009 1.11792 9.63541L6.91341 4.40685C7.20855 4.14534 7.59646 4 7.99928 4C8.4021 4 8.79001 4.14534 9.08515 4.40685L14.8827 9.63041C15.0249 9.75509 15.1383 9.90655 15.216 10.0752C15.2936 10.2439 15.3337 10.4261 15.3337 10.6103C15.3337 10.7945 15.2936 10.9767 15.216 11.1453C15.1383 11.314 15.0249 11.4655 14.8827 11.5901C14.5871 11.8497 14.1992 11.9926 13.7974 11.9899"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7974 11.9949C13.3945 11.9981 13.0055 11.8552 12.7089 11.5951L7.9998 7.34592L3.29175 11.5951C2.99558 11.8555 2.60758 12 2.20483 12C1.80209 12 1.41408 11.8555 1.11792 11.5951C0.975773 11.4704 0.862304 11.319 0.784686 11.1503C0.707069 10.9817 0.666992 10.7995 0.666992 10.6153C0.666992 10.4311 0.707069 10.2489 0.784686 10.0802C0.862304 9.91155 0.975773 9.76009 1.11792 9.63541L6.91341 4.40685C7.20855 4.14534 7.59646 4 7.99928 4C8.4021 4 8.79001 4.14534 9.08515 4.40685L14.8827 9.63041C15.0249 9.75509 15.1383 9.90655 15.216 10.0752C15.2936 10.2439 15.3337 10.4261 15.3337 10.6103C15.3337 10.7945 15.2936 10.9767 15.216 11.1453C15.1383 11.314 15.0249 11.4655 14.8827 11.5901C14.5871 11.8497 14.1992 11.9926 13.7974 11.9899"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7974 11.9949C13.3945 11.9981 13.0055 11.8552 12.7089 11.5951L7.9998 7.34592L3.29175 11.5951C2.99558 11.8555 2.60758 12 2.20483 12C1.80209 12 1.41408 11.8555 1.11792 11.5951C0.975773 11.4704 0.862304 11.319 0.784686 11.1503C0.707069 10.9817 0.666992 10.7995 0.666992 10.6153C0.666992 10.4311 0.707069 10.2489 0.784686 10.0802C0.862304 9.91155 0.975773 9.76009 1.11792 9.63541L6.91341 4.40685C7.20855 4.14534 7.59646 4 7.99928 4C8.4021 4 8.79001 4.14534 9.08515 4.40685L14.8827 9.63041C15.0249 9.75509 15.1383 9.90655 15.216 10.0752C15.2936 10.2439 15.3337 10.4261 15.3337 10.6103C15.3337 10.7945 15.2936 10.9767 15.216 11.1453C15.1383 11.314 15.0249 11.4655 14.8827 11.5901C14.5871 11.8497 14.1992 11.9926 13.7974 11.9899"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7974 11.9949C13.3945 11.9981 13.0055 11.8552 12.7089 11.5951L7.9998 7.34592L3.29175 11.5951C2.99558 11.8555 2.60758 12 2.20483 12C1.80209 12 1.41408 11.8555 1.11792 11.5951C0.975773 11.4704 0.862304 11.319 0.784686 11.1503C0.707069 10.9817 0.666992 10.7995 0.666992 10.6153C0.666992 10.4311 0.707069 10.2489 0.784686 10.0802C0.862304 9.91155 0.975773 9.76009 1.11792 9.63541L6.91341 4.40685C7.20855 4.14534 7.59646 4 7.99928 4C8.4021 4 8.79001 4.14534 9.08515 4.40685L14.8827 9.63041C15.0249 9.75509 15.1383 9.90655 15.216 10.0752C15.2936 10.2439 15.3337 10.4261 15.3337 10.6103C15.3337 10.7945 15.2936 10.9767 15.216 11.1453C15.1383 11.314 15.0249 11.4655 14.8827 11.5901C14.5871 11.8497 14.1992 11.9926 13.7974 11.9899"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7974 11.9949C13.3945 11.9981 13.0055 11.8552 12.7089 11.5951L7.9998 7.34592L3.29175 11.5951C2.99558 11.8555 2.60758 12 2.20483 12C1.80209 12 1.41408 11.8555 1.11792 11.5951C0.975773 11.4704 0.862304 11.319 0.784686 11.1503C0.707069 10.9817 0.666992 10.7995 0.666992 10.6153C0.666992 10.4311 0.707069 10.2489 0.784686 10.0802C0.862304 9.91155 0.975773 9.76009 1.11792 9.63541L6.91341 4.40685C7.20855 4.14534 7.59646 4 7.99928 4C8.4021 4 8.79001 4.14534 9.08515 4.40685L14.8827 9.63041C15.0249 9.75509 15.1383 9.90655 15.216 10.0752C15.2936 10.2439 15.3337 10.4261 15.3337 10.6103C15.3337 10.7945 15.2936 10.9767 15.216 11.1453C15.1383 11.314 15.0249 11.4655 14.8827 11.5901C14.5871 11.8497 14.1992 11.9926 13.7974 11.9899"
            fill={color}
            fillOpacity="0.2"
          />
        </g>
      </g>
    </svg>
  );
};

export default ArrowUp;
