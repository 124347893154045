const changeLocaleDateString = (date: string) => {
  return new Date(date).toLocaleDateString('ko-KR', {
    timeZone: 'Asia/Seoul',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
};

export default changeLocaleDateString;
