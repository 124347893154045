import {
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled, { css, keyframes } from 'styled-components';
import GlobalContainer from './GlobalContainer';
import {
  BodySubmenu,
  Body_Text_16,
  SubChip,
  SubTitle,
  TextButton,
  palette,
} from 'modules/defines/styles';
import { useLocation } from 'react-router';
import { icon_check, icon_check_nofill } from 'assets/icon';
import { externalUrls } from 'modules/defines/paths';
import { decodeFromBase64, encodeToBase64 } from 'modules/defines/encrypt';
import { Option, Product } from 'modules/defines/interfaces';
import { productDummy } from 'modules/defines/dummy';
import { ButtonSolid60 } from 'components/common/Button';
import { AlertModal, Modal } from 'components/common/Modal';
import { getOptionFinalPrice, printCleanDiscount } from 'utils/util';
import RefundInfo from 'components/ProductDetail/RefundInfo';
import { paymentButtonClickLog, viewPageLog } from 'utils/amplitude';
import { facebookPixelTrack } from 'utils/facebookPixel';
import Header from 'components/common/Header';
import { UserAgent } from 'utils/userAgent';

const PurchaseContainer = () => {
  const { os, browser, device } = UserAgent;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const productInfo = queryParams.get('p');
  const optionInfo = queryParams.get('opt');
  const countInfo = queryParams.get('cnt');
  const userIdEnc = queryParams.get('uid');

  // Renewal
  const [optionsSelected, setOptionsSelected] = useState<Option[]>([]);
  const [product, setProduct] = useState<Product | null>(null);
  const [finalPrice, setFinalPrice] = useState<number>(0);
  const [userId, setUserId] = useState<string>('');

  const [inputName, setInputName] = useState('');
  const [inputPhone, setInputPhone] = useState('');
  const [checkAgree, setCheckAgree] = useState(false);
  const onChangeInputName = (e: any) => setInputName(e.target.value);
  const phoneRef = useRef<HTMLInputElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInputModalOpen, setIsInputModalOpen] = useState(false);
  const [isNonMemberModalOpen, setIsNonMemberModalOpen] = useState(false);
  const [isCheckNonMemberInfo, setIsCheckNonMemberInfo] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  // Initialize (Query)
  const nameInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const handleResize = () => {
      document.body.style.willChange = 'scroll-position';
      if (nameInputRef.current) {
        nameInputRef.current.scrollIntoView({ behavior: 'auto' });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    var pInfoDec = decodeFromBase64(productInfo);
    var optInfoDec = decodeFromBase64(optionInfo);
    var userIdDec = userIdEnc !== null ? decodeFromBase64(userIdEnc) : '0';

    const pInfoDecJSON = JSON.parse(pInfoDec);
    const optInfoDecJSON = JSON.parse(optInfoDec);
    if (pInfoDecJSON) {
      viewPageLog('purchase', pInfoDecJSON.id.toString()); //amplitude tracking
      setProduct(pInfoDecJSON as Product);
    }
    if (optInfoDecJSON) {
      setOptionsSelected(optInfoDecJSON as Option[]);
    }
    if (userIdDec !== '0') setUserId(userIdDec);
    else setUserId('0'); // 비회원
  }, []);

  // Final Price 계산
  useEffect(() => {
    if (product === null) return;
    if (
      product.options === null ||
      (product.options !== undefined && product.options.length === 0)
    ) {
      // < 옵션 O >
      setFinalPrice(
        ((product.price * (100 - product.discount)) / 100) * Number(countInfo)
      );
    } else {
      // < 옵션 X >
      let finalPrice = 0;
      optionsSelected.map((option: Option) => {
        finalPrice += getOptionFinalPrice(
          product.discount.toString(),
          product.price.toString(),
          option.price,
          option.count
        );
      });
      setFinalPrice(Math.floor(finalPrice / 10) * 10);
    }
  }, [product, optionsSelected]);

  //멤버 여부 확인
  const handleIsMember = () => {
    if (userId === '0') {
      setIsCheckNonMemberInfo(true);
    } else {
      handlePayment();
      handleSendInfoToAmplitude();
    }
  };

  //넘기기 전 성인용 서비스 이용 가능 여부 확인
  const handlePayment = () => {
    if (product === null) return;
    if (product.adultOnly) {
      setIsModalOpen(true);
    } else {
      handlePurchaseInfo();
    }
  };

  //정보 넘기기
  const handlePurchaseInfo = () => {
    if (product === null) return;
    const paymentInfo = {
      user_id: userId,
      productDBId: product.id,
      productId: product.productId,
      productName: product.title,
      priceOrigin: product.price,
      price: Math.floor(finalPrice / 10) * 10,
      discount: product.discount,
      customerName: inputName,
      customerPhone: inputPhone,
      selectedOption: optionsSelected,
      count:
        product.options === null ||
        (product.options !== undefined && product.options.length === 0)
          ? Number(countInfo)
          : 1,
      spaceTitle: product.spaceTitle,
    };
    const paymentInfoEnc = encodeToBase64(JSON.stringify(paymentInfo));
    window.location.href = `/payment?p=${paymentInfoEnc}`;
  };

  const handleSendInfoToAmplitude = () => {
    if (product === null) return;
    paymentButtonClickLog(product.spaceTitle, product.title);
    facebookPixelTrack('Click Payment Button', {
      spaceTitle: product.spaceTitle,
      productTitle: product.title,
    });
  };

  //결제 하기 버튼을 눌렀을 때
  const onClickPayButton = async (e: MouseEvent) => {
    e.preventDefault();
    if (inputName === '' || inputPhone === '') {
      setIsInputModalOpen(true);
      return;
    } else {
      handleIsMember();
    }
  };

  const handlePhoneNumberHyphen = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (phoneRef.current) {
      const value = phoneRef.current.value.replace(/\D+/g, '');
      const numberLength = 11;
      let result;
      result = '';

      for (let i = 0; i < value.length && i < numberLength; i++) {
        switch (i) {
          case 3:
            result += '-';
            break;
          case 7:
            result += '-';
            break;
          default:
            break;
        }
        result += value[i];
      }
      if (phoneRef.current) phoneRef.current.value = result;
      setInputPhone(e.target.value);
    }
  };

  if (product === null) {
    return (
      <GlobalContainer>
        <SectionContainer>
          <Title>구매 상품 정보</Title>
          <Loading height={22} marinBottom={4} />
          <Loading height={20} marinBottom={16} />
          <Loading marinBottom={5} height={88} />
          <Loading height={88} marinBottom={20} />
          <Loading height={88} marinBottom={40} />
        </SectionContainer>
        <SectionContainer>
          <Title>환불 안내</Title>
          <Loading height={227} marinBottom={16} />
        </SectionContainer>
        <SectionContainer2>
          <Title>{userId === '0' ? '비회원 구매자 정보' : '구매자 정보'}</Title>
          <Loading height={88} marinBottom={16} />
          <Loading height={88} marinBottom={16} />
        </SectionContainer2>
      </GlobalContainer>
    );
  }
  return (
    <GlobalContainer>
      {isNonMemberModalOpen && (
        <AlertModal
          isPadding={false}
          onCancel={() => {
            setIsNonMemberModalOpen(false);
            handlePayment();
          }}
          title="알림"
        >
          비회원 결제로 진행됩니다
        </AlertModal>
      )}
      {isInputModalOpen && (
        <AlertModal
          isPadding={false}
          onCancel={() => setIsInputModalOpen(false)}
          title="알림"
        >
          이름과 휴대폰 번호를 입력해주세요
        </AlertModal>
      )}
      {isCheckNonMemberInfo && (
        <Modal
          isPadding={false}
          size="half"
          title={`입력하신 휴대폰 번호를 \n한 번 더 확인해 주세요.`}
          onConfirm={() => {
            setIsCheckNonMemberInfo(false);
            setIsNonMemberModalOpen(true);
            handleSendInfoToAmplitude();
          }}
          onCancel={() => {
            setIsCheckNonMemberInfo(false);
          }}
          confirmText="완료"
          cancelText="돌아가기"
        >
          주문번호와 상품 사용을 위한 QR코드를
          <br />
          입력하신 휴대폰 번호로 전달 드립니다.
        </Modal>
      )}
      {isModalOpen && (
        <Modal
          isPadding
          size="2:8"
          title="청소년 이용불가"
          onConfirm={() => {
            handlePurchaseInfo();
          }}
          onCancel={() => {
            setIsModalOpen(false);
          }}
          confirmText="동의 후 결제"
          cancelText="취소"
        >
          본 상품은 19세 미만 청소년은 이용이 불가합니다.
          <Accent>취소/환불 규정</Accent> 에 따라 결제 취소 가능하며, 취소
          수수료가 발생할 수 있습니다.
        </Modal>
      )}
      <Header isFavApp={browser.isFavApp} title={''} />
      {product && (
        <SectionContainer>
          <Title>구매 상품 정보</Title>
          <ProductManagerText>{product.spaceTitle}</ProductManagerText>
          <ProductTitleText>{product.title}</ProductTitleText>
          <SelectedOptionContainer>
            {optionsSelected.length !== 0 ? (
              optionsSelected.map((option, index) => {
                if (option.count !== 0) {
                  return (
                    <OptionContainer key={`opt_${index}`}>
                      <OptionTitle>{option.title}</OptionTitle>
                      <OptionNumberRow>
                        <ProductCountText>{option.count}개</ProductCountText>
                        <PriceWrapper>
                          <DiscountText>
                            {printCleanDiscount(product.discount.toString())}%
                          </DiscountText>
                          <PriceText>
                            {getOptionFinalPrice(
                              product.discount.toString(),
                              product.price.toString(),
                              option.price,
                              option.count
                            ).toLocaleString()}
                            원
                          </PriceText>
                        </PriceWrapper>
                      </OptionNumberRow>
                    </OptionContainer>
                  );
                }
              })
            ) : (
              <OptionContainer>
                <OptionTitle>{product.title}</OptionTitle>
                <OptionNumberRow>
                  <ProductCountText>{countInfo}개</ProductCountText>
                  <PriceWrapper>
                    <DiscountText>
                      {printCleanDiscount(product.discount.toString())}%
                    </DiscountText>
                    <PriceText>
                      {countInfo && finalPrice.toLocaleString()}원
                    </PriceText>
                  </PriceWrapper>
                </OptionNumberRow>
              </OptionContainer>
            )}
          </SelectedOptionContainer>
          <LightBorder />
          <TotalPriceRow>
            <TotalPriceText>결제 금액</TotalPriceText>
            <TotalPrice>{finalPrice.toLocaleString()}원</TotalPrice>
          </TotalPriceRow>
        </SectionContainer>
      )}
      <SectionContainer>
        <RefundInfo fontStyle="Badge" category={product.category} />
      </SectionContainer>
      <SectionContainer2>
        <Title>{userId === '0' ? '비회원 구매자 정보' : '구매자 정보'}</Title>
        <UserInfoTitle>이름</UserInfoTitle>
        <UserInfoInput
          ref={nameInputRef}
          type="text"
          placeholder="실명을 입력해주세요."
          value={inputName}
          onChange={onChangeInputName}
        />
        <UserInfoTitle>휴대폰 번호</UserInfoTitle>
        <UserInfoInput
          type="tel"
          ref={phoneRef}
          placeholder="EX) 010-1234-5678"
          value={inputPhone}
          onChange={handlePhoneNumberHyphen}
        />
        {userId === '0' && (
          <CheckBoxLabel htmlFor="agreeNonMemberPayment">
            <input
              checked={checkAgree}
              onChange={(e) => setCheckAgree(e.target.checked)}
              type="checkbox"
              id="agreeNonMemberPayment"
              hidden
            />
            <CheckBoxIcon
              className={checkAgree ? 'checked' : ''}
              width={20}
              height={20}
              src={checkAgree ? icon_check : icon_check_nofill}
            />
            비회원 결제 시, 입력하신 휴대폰 번호로 주문 번호와 상품의 QR코드를
            전달 드립니다. 휴대폰 번호를 확인했습니다.
          </CheckBoxLabel>
        )}
      </SectionContainer2>

      <SectionContainer>
        <PolicyRow>
          <PolicyRowButton href={externalUrls.termsOfService} target="_blank">
            이용 약관
          </PolicyRowButton>
          <PolicyRowText>및</PolicyRowText>
          <PolicyRowButton href={externalUrls.privacyPolicy} target="_blank">
            개인정보 수집∙이용
          </PolicyRowButton>
          <PolicyRowText>에 동의하시면 진행해 주세요</PolicyRowText>
        </PolicyRow>
        <ButtonContainer>
          <ButtonSolid60
            disabled={userId === '0' ? !checkAgree : false}
            btype="secondary"
            onClick={(e) => {
              onClickPayButton(e);
            }}
          >
            {finalPrice.toLocaleString()}원 결제하기
          </ButtonSolid60>
        </ButtonContainer>
      </SectionContainer>
    </GlobalContainer>
  );
};

const loading = keyframes`

  0% {
    background-color: rgba(0, 0, 0, 0.04);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.08);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const animation = keyframes`
from{
  scale: 0.8
} to {
  scale: 1
}
`;

const Loading = styled.div<{ height: number; marinBottom?: number }>`
  animation: ${loading} 1s linear infinite;
  width: 100%;
  height: ${(props) => props.height}px;
  margin-bottom: ${(props) => props.marinBottom}px;
`;

const CheckBoxIcon = styled.img`
  &.checked {
    animation: ${animation} 0.3s forwards;
  }
`;

const CheckBoxLabel = styled.label`
  ${SubChip};
  color: ${palette.neutral006};
  display: flex;
  gap: 0.5rem;
`;

const Accent = styled.em`
  color: #ea4d5d;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem; /* 133.333% */
  text-decoration: underline;
  text-underline-offset: 0.125rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PolicyRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0.9375rem;
`;
const PolicyRowButton = styled.a`
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 500;
  color: #0097ff;
  line-height: 1rem;
  text-decoration: underline;
`;
const PolicyRowText = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  color: #717171;
  line-height: 1rem;
  margin: 0 0.1875rem;
`;

const SectionContainer = styled.div`
  margin: 0 25px 50px 25px;
`;

const SectionContainer2 = styled.div`
  margin: 0 25px 70px 25px;
`;
const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  color: #1e1e1e;
  line-height: 1.5rem;
  border-bottom: 1px solid #717171;
  box-sizing: border-box;
  padding-bottom: 1rem;
  margin-bottom: 1.25rem;
`;
const ProductManagerText = styled.div`
  color: ${palette.neutral007};
  ${SubTitle};

  margin-bottom: 4px;
`;
const ProductTitleText = styled.div`
  color: ${palette.neutral007};
  ${TextButton};
  margin-bottom: 16px;
`;
const SelectedOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 1.25rem;
  gap: 0.3125rem;
`;
const ProductCountText = styled.div`
  color: ${palette.neutral006};
  ${BodySubmenu};
`;
const TotalPriceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
`;
const TotalPriceText = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  color: #393939;
  line-height: 1.375rem;
`;
const TotalPrice = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  color: #0097ff;
  line-height: 1.5rem;
`;

// const InfoRow = styled.div`
//   display: flex;
//   justify-content: space-between;
//   border: 1px solid #d9d9d9;
//   margin-bottom: 15px;
//   border-radius: 6px;
// `;

// const InfoDate = styled.div`
//   font-size: 15px;
//   font-weight: 400;
//   color: #393939;
//   line-height: 20px;
//   font-family: 'Pretendard';
//   text-align: left;
//   padding: 12px 20px;
// `;
// const InfoValue = styled.div`
//   font-size: 15px;
//   font-weight: 400;
//   color: #393939;
//   line-height: 20px;
//   font-family: 'Pretendard';
//   text-align: center;
//   padding: 12px 20px;
//   border-left: 1px solid #d9d9d9;
// `;
const UserInfoTitle = styled.div`
  margin-bottom: 0.9375rem;
  color: ${palette.neutral005};
  ${BodySubmenu};
`;
const UserInfoInput = styled.input`
  margin-bottom: 1.25rem;
  width: 100%;
  padding: 1.25rem;
  border-radius: 20px;
  border: 1px solid #c1c0c9;
  box-sizing: border-box;
  color: ${palette.neutral007};
  ${TextButton};
`;

const OptionTitle = styled.div`
  color: ${palette.neutral005};

  ${Body_Text_16};
`;

const OptionNumberRow = styled.div`
  margin-top: 0.625rem;
  display: flex;
  justify-content: space-between;
`;
const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.1875rem;
  align-items: center; // FIXMEFIXME
`;

const DiscountText = styled.div`
  color: ${palette.primary};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 160%; /* 1.5rem */
  letter-spacing: -0.0187rem;
`;
const PriceText = styled.div`
  color: ${palette.neutral006};
  ${BodySubmenu};
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${palette.neutral001};
  padding: 0.9375rem;
  border-radius: 10px;
`;

const LightBorder = styled.div`
  background: ${palette.neutral003};
  height: 1px;
  align-self: stretch;
  margin-bottom: 0.9375rem;
`;

export default PurchaseContainer;
