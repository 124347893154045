import { Player } from '@lottiefiles/react-lottie-player';
import { icon_close_gray } from 'assets/icon';
import { spinner } from 'assets/lottie';
import { Button, palette } from 'modules/defines/styles';
import { PropsWithChildren, useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { allowScroll, preventScroll } from 'utils/handleScroll';
interface ModalProps {
  size: 'half' | '2:8';
  title: string;
  children: PropsWithChildren<React.ReactNode>;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText: string;
  cancelText: string;
  isPadding: boolean;
  isShowCloseButton?: boolean;
  onCloseButton?: () => void;
  isLoading?: boolean;
}

interface AlertModalProps {
  title: string;
  children: PropsWithChildren<React.ReactNode>;
  onCancel: () => void;
  isPadding: boolean;
}

interface InputModalProps {
  onConfirm: (name: string, phone: string) => void;
  isPadding: boolean;
  isShowCloseButton?: boolean;
  onCloseButton?: () => void;
}

export const AlertModal: React.FC<AlertModalProps> = ({
  title,
  children,
  onCancel,
  isPadding,
}) => {
  return (
    <ModalContainer>
      <ModalContent>
        <ModalTitle>{title}</ModalTitle>
        <ModalDescription isPadding={isPadding}>{children}</ModalDescription>
        <FullButtonLayout>
          <FullModalButton type="button" onClick={onCancel}>
            확인
          </FullModalButton>
        </FullButtonLayout>
      </ModalContent>
    </ModalContainer>
  );
};

export const Modal: React.FC<ModalProps> = ({ ...props }) => {
  const {
    size,
    title,
    children,
    onConfirm,
    onCancel,
    confirmText,
    cancelText,
    isPadding,
    isShowCloseButton,
    onCloseButton,
    isLoading = false,
  } = props;

  useEffect(() => {
    const prevScrollY = preventScroll();
    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  return (
    <ModalContainer>
      <ModalContent>
        {isShowCloseButton && (
          <CloseButton onClick={onCloseButton} type="button">
            <img width={24} height={24} src={icon_close_gray} alt="close" />
          </CloseButton>
        )}
        <ModalTitle>{title}</ModalTitle>
        <ModalDescription isPadding={isPadding}>{children}</ModalDescription>
        <ModalButtonWrapper size={size}>
          <ModalButton
            onClick={onCancel}
            size={size}
            type="button"
            className="close"
          >
            {cancelText}
          </ModalButton>
          <ModalButton
            onClick={onConfirm}
            size={size}
            type="button"
            className="complete"
            disabled={isLoading}
            isLoading={isLoading}
          >
            {isLoading && (
              <LoadingContainer>
                <Player
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                  id="spinner_player"
                  src={spinner}
                  autoplay
                  loop
                />
              </LoadingContainer>
            )}
            {confirmText}
          </ModalButton>
        </ModalButtonWrapper>
      </ModalContent>
    </ModalContainer>
  );
};

export const InputModal: React.FC<InputModalProps> = ({ ...props }) => {
  const { onConfirm, isPadding, isShowCloseButton, onCloseButton } = props;
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const phoneRef = useRef<HTMLInputElement>(null);

  const handleConfirm = () => {
    onConfirm(username, phoneNumber);
  };

  const handlePhoneNumberHyphen = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (phoneRef.current) {
      const value = phoneRef.current.value.replace(/\D+/g, '');
      const numberLength = 11;
      let result;
      result = '';

      for (let i = 0; i < value.length && i < numberLength; i++) {
        switch (i) {
          case 3:
            result += '-';
            break;
          case 7:
            result += '-';
            break;
          default:
            break;
        }
        result += value[i];
      }
      if (phoneRef.current) phoneRef.current.value = result;
      setPhoneNumber(e.target.value);
    }
  };

  return (
    <ModalContainer>
      <ModalContent>
        {isShowCloseButton && (
          <CloseButton onClick={onCloseButton} type="button">
            <img width={24} height={24} src={icon_close_gray} alt="close" />
          </CloseButton>
        )}
        <ModalTitle>결제 상품 확인</ModalTitle>
        <ModalDescription isPadding={isPadding}>
          <InputWrapper>
            <StyledInput
              type="text"
              placeholder="이름"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <StyledInput
              type="text"
              ref={phoneRef}
              placeholder="휴대폰 번호"
              value={phoneNumber}
              onChange={handlePhoneNumberHyphen}
            />
          </InputWrapper>
        </ModalDescription>
        <InputModalButtonWrapper>
          <InputModalButton
            onClick={handleConfirm}
            type="button"
            className="complete"
          >
            확인하기
          </InputModalButton>
        </InputModalButtonWrapper>
      </ModalContent>
    </ModalContainer>
  );
};

const LoadingContainer = styled.div`
  margin-right: 0.25rem;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  margin-top: 1.25rem;
`;

const StyledInput = styled.input`
  padding: 0.625rem 0.9375rem;
  font-size: 0.875rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
`;

const ModalContainer = styled.div`
  max-width: 40rem;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background-color: rgba(30, 30, 30, 0.4);
`;

const ModalTitle = styled.h3`
  color: #000;
  text-align: center;
  white-space: pre-line;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

const ModalDescription = styled.p<{ isPadding: boolean }>`
  padding: ${(props) => (props.isPadding ? '0 21px' : '0px')};
  text-align: center;
  color: #717171;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem; /* 133.333% */
  white-space: pre-wrap;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 13px;
  right: 13px;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: #fff;
  box-sizing: border-box;
  width: calc(40rem - 116px);
  max-width: 321px;
  padding: 2.5rem 1.5625rem;
  border-radius: 20px;
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const FullModalButton = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 10px;
  color: #fff;
  background: #1e1e1e;
  ${Button};
  &:active {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.3) 100%
      ),
      #1e1e1e;
  }
`;
const FullButtonLayout = styled.div`
  display: flex;
  margin-top: 1.25rem;
`;
const InputModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.25rem;
`;

const InputModalButton = styled.button`
  width: 100%;
  padding: 0 1.875rem;
  height: 3rem;
  border-radius: 10px;
  color: #fff;
  background: #1e1e1e;
  border: none;
  cursor: pointer;
  ${Button};
  &:active {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.3) 100%
      ),
      #1e1e1e;
  }
`;

const ModalButtonWrapper = styled.div<{ size: 'half' | '2:8' }>`
  display: flex;
  gap: ${({ size }) => (size === 'half' ? '0.5rem' : '0.25rem')};
  justify-content: center;
  margin-top: 1.25rem;
`;
const ModalButton = styled.button<{
  size: 'half' | '2:8';
  isLoading?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &.close {
    height: 48px;
    width: 100%;
    max-width: 112px;
    border-radius: 10px;
    color: #717171;
    border: 1px solid #979797;
    background: #fff;
    ${Button};
    &:active {
      background: #f4f4f4;
      color: #979797;
    }
  }
  &.complete {
    width: 100%;
    max-width: ${({ size }) => (size === 'half' ? '112px' : '146px')};
    border-radius: 10px;
    color: #fff;
    background: #1e1e1e;
    ${Button};
    &:active {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.3) 0%,
          rgba(255, 255, 255, 0.3) 100%
        ),
        #1e1e1e;
    }
    ${(props) =>
      props.isLoading &&
      css`
        background-color: ${palette.neutral002};
        color: ${palette.neutral004};
      `}
  }
`;
