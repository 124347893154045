import React from 'react';
import { palette } from 'modules/defines/styles';

interface IconProps {
  disabled?: boolean;
}

const ArrowDown: React.FC<IconProps> = ({ disabled = false }) => {
  var color = palette.neutral007;
  if (disabled) color = palette.neutral004;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Arrows / arrow-down-1">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.20289 4.00014C2.60565 3.99688 2.99466 4.13986 3.29115 4.40013L8.00449 8.64699L12.7095 4.40513C13.0056 4.14462 13.3935 4 13.7961 4C14.1988 4 14.5867 4.14462 14.8828 4.40513C15.0249 4.52988 15.1384 4.68144 15.216 4.85021C15.2936 5.01898 15.3337 5.20128 15.3337 5.3856C15.3337 5.56991 15.2936 5.75222 15.216 5.92099C15.1384 6.08976 15.0249 6.24131 14.8828 6.36607L9.08542 11.5929C8.79034 11.8546 8.40251 12 7.99978 12C7.59705 12 7.20922 11.8546 6.91414 11.5929L1.11882 6.36607C0.976427 6.24147 0.862727 6.08997 0.784943 5.92118C0.70716 5.75239 0.666992 5.57 0.666992 5.3856C0.666992 5.20119 0.70716 5.01881 0.784943 4.85002C0.862727 4.68123 0.976427 4.52972 1.11882 4.40513C1.41311 4.14346 1.80075 3.99865 2.20289 4.00014Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.20289 4.00014C2.60565 3.99688 2.99466 4.13986 3.29115 4.40013L8.00449 8.64699L12.7095 4.40513C13.0056 4.14462 13.3935 4 13.7961 4C14.1988 4 14.5867 4.14462 14.8828 4.40513C15.0249 4.52988 15.1384 4.68144 15.216 4.85021C15.2936 5.01898 15.3337 5.20128 15.3337 5.3856C15.3337 5.56991 15.2936 5.75222 15.216 5.92099C15.1384 6.08976 15.0249 6.24131 14.8828 6.36607L9.08542 11.5929C8.79034 11.8546 8.40251 12 7.99978 12C7.59705 12 7.20922 11.8546 6.91414 11.5929L1.11882 6.36607C0.976427 6.24147 0.862727 6.08997 0.784943 5.92118C0.70716 5.75239 0.666992 5.57 0.666992 5.3856C0.666992 5.20119 0.70716 5.01881 0.784943 4.85002C0.862727 4.68123 0.976427 4.52972 1.11882 4.40513C1.41311 4.14346 1.80075 3.99865 2.20289 4.00014Z"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.20289 4.00014C2.60565 3.99688 2.99466 4.13986 3.29115 4.40013L8.00449 8.64699L12.7095 4.40513C13.0056 4.14462 13.3935 4 13.7961 4C14.1988 4 14.5867 4.14462 14.8828 4.40513C15.0249 4.52988 15.1384 4.68144 15.216 4.85021C15.2936 5.01898 15.3337 5.20128 15.3337 5.3856C15.3337 5.56991 15.2936 5.75222 15.216 5.92099C15.1384 6.08976 15.0249 6.24131 14.8828 6.36607L9.08542 11.5929C8.79034 11.8546 8.40251 12 7.99978 12C7.59705 12 7.20922 11.8546 6.91414 11.5929L1.11882 6.36607C0.976427 6.24147 0.862727 6.08997 0.784943 5.92118C0.70716 5.75239 0.666992 5.57 0.666992 5.3856C0.666992 5.20119 0.70716 5.01881 0.784943 4.85002C0.862727 4.68123 0.976427 4.52972 1.11882 4.40513C1.41311 4.14346 1.80075 3.99865 2.20289 4.00014Z"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.20289 4.00014C2.60565 3.99688 2.99466 4.13986 3.29115 4.40013L8.00449 8.64699L12.7095 4.40513C13.0056 4.14462 13.3935 4 13.7961 4C14.1988 4 14.5867 4.14462 14.8828 4.40513C15.0249 4.52988 15.1384 4.68144 15.216 4.85021C15.2936 5.01898 15.3337 5.20128 15.3337 5.3856C15.3337 5.56991 15.2936 5.75222 15.216 5.92099C15.1384 6.08976 15.0249 6.24131 14.8828 6.36607L9.08542 11.5929C8.79034 11.8546 8.40251 12 7.99978 12C7.59705 12 7.20922 11.8546 6.91414 11.5929L1.11882 6.36607C0.976427 6.24147 0.862727 6.08997 0.784943 5.92118C0.70716 5.75239 0.666992 5.57 0.666992 5.3856C0.666992 5.20119 0.70716 5.01881 0.784943 4.85002C0.862727 4.68123 0.976427 4.52972 1.11882 4.40513C1.41311 4.14346 1.80075 3.99865 2.20289 4.00014Z"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.20289 4.00014C2.60565 3.99688 2.99466 4.13986 3.29115 4.40013L8.00449 8.64699L12.7095 4.40513C13.0056 4.14462 13.3935 4 13.7961 4C14.1988 4 14.5867 4.14462 14.8828 4.40513C15.0249 4.52988 15.1384 4.68144 15.216 4.85021C15.2936 5.01898 15.3337 5.20128 15.3337 5.3856C15.3337 5.56991 15.2936 5.75222 15.216 5.92099C15.1384 6.08976 15.0249 6.24131 14.8828 6.36607L9.08542 11.5929C8.79034 11.8546 8.40251 12 7.99978 12C7.59705 12 7.20922 11.8546 6.91414 11.5929L1.11882 6.36607C0.976427 6.24147 0.862727 6.08997 0.784943 5.92118C0.70716 5.75239 0.666992 5.57 0.666992 5.3856C0.666992 5.20119 0.70716 5.01881 0.784943 4.85002C0.862727 4.68123 0.976427 4.52972 1.11882 4.40513C1.41311 4.14346 1.80075 3.99865 2.20289 4.00014Z"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.20289 4.00014C2.60565 3.99688 2.99466 4.13986 3.29115 4.40013L8.00449 8.64699L12.7095 4.40513C13.0056 4.14462 13.3935 4 13.7961 4C14.1988 4 14.5867 4.14462 14.8828 4.40513C15.0249 4.52988 15.1384 4.68144 15.216 4.85021C15.2936 5.01898 15.3337 5.20128 15.3337 5.3856C15.3337 5.56991 15.2936 5.75222 15.216 5.92099C15.1384 6.08976 15.0249 6.24131 14.8828 6.36607L9.08542 11.5929C8.79034 11.8546 8.40251 12 7.99978 12C7.59705 12 7.20922 11.8546 6.91414 11.5929L1.11882 6.36607C0.976427 6.24147 0.862727 6.08997 0.784943 5.92118C0.70716 5.75239 0.666992 5.57 0.666992 5.3856C0.666992 5.20119 0.70716 5.01881 0.784943 4.85002C0.862727 4.68123 0.976427 4.52972 1.11882 4.40513C1.41311 4.14346 1.80075 3.99865 2.20289 4.00014Z"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.20289 4.00014C2.60565 3.99688 2.99466 4.13986 3.29115 4.40013L8.00449 8.64699L12.7095 4.40513C13.0056 4.14462 13.3935 4 13.7961 4C14.1988 4 14.5867 4.14462 14.8828 4.40513C15.0249 4.52988 15.1384 4.68144 15.216 4.85021C15.2936 5.01898 15.3337 5.20128 15.3337 5.3856C15.3337 5.56991 15.2936 5.75222 15.216 5.92099C15.1384 6.08976 15.0249 6.24131 14.8828 6.36607L9.08542 11.5929C8.79034 11.8546 8.40251 12 7.99978 12C7.59705 12 7.20922 11.8546 6.91414 11.5929L1.11882 6.36607C0.976427 6.24147 0.862727 6.08997 0.784943 5.92118C0.70716 5.75239 0.666992 5.57 0.666992 5.3856C0.666992 5.20119 0.70716 5.01881 0.784943 4.85002C0.862727 4.68123 0.976427 4.52972 1.11882 4.40513C1.41311 4.14346 1.80075 3.99865 2.20289 4.00014Z"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.20289 4.00014C2.60565 3.99688 2.99466 4.13986 3.29115 4.40013L8.00449 8.64699L12.7095 4.40513C13.0056 4.14462 13.3935 4 13.7961 4C14.1988 4 14.5867 4.14462 14.8828 4.40513C15.0249 4.52988 15.1384 4.68144 15.216 4.85021C15.2936 5.01898 15.3337 5.20128 15.3337 5.3856C15.3337 5.56991 15.2936 5.75222 15.216 5.92099C15.1384 6.08976 15.0249 6.24131 14.8828 6.36607L9.08542 11.5929C8.79034 11.8546 8.40251 12 7.99978 12C7.59705 12 7.20922 11.8546 6.91414 11.5929L1.11882 6.36607C0.976427 6.24147 0.862727 6.08997 0.784943 5.92118C0.70716 5.75239 0.666992 5.57 0.666992 5.3856C0.666992 5.20119 0.70716 5.01881 0.784943 4.85002C0.862727 4.68123 0.976427 4.52972 1.11882 4.40513C1.41311 4.14346 1.80075 3.99865 2.20289 4.00014Z"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.20289 4.00014C2.60565 3.99688 2.99466 4.13986 3.29115 4.40013L8.00449 8.64699L12.7095 4.40513C13.0056 4.14462 13.3935 4 13.7961 4C14.1988 4 14.5867 4.14462 14.8828 4.40513C15.0249 4.52988 15.1384 4.68144 15.216 4.85021C15.2936 5.01898 15.3337 5.20128 15.3337 5.3856C15.3337 5.56991 15.2936 5.75222 15.216 5.92099C15.1384 6.08976 15.0249 6.24131 14.8828 6.36607L9.08542 11.5929C8.79034 11.8546 8.40251 12 7.99978 12C7.59705 12 7.20922 11.8546 6.91414 11.5929L1.11882 6.36607C0.976427 6.24147 0.862727 6.08997 0.784943 5.92118C0.70716 5.75239 0.666992 5.57 0.666992 5.3856C0.666992 5.20119 0.70716 5.01881 0.784943 4.85002C0.862727 4.68123 0.976427 4.52972 1.11882 4.40513C1.41311 4.14346 1.80075 3.99865 2.20289 4.00014Z"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.20289 4.00014C2.60565 3.99688 2.99466 4.13986 3.29115 4.40013L8.00449 8.64699L12.7095 4.40513C13.0056 4.14462 13.3935 4 13.7961 4C14.1988 4 14.5867 4.14462 14.8828 4.40513C15.0249 4.52988 15.1384 4.68144 15.216 4.85021C15.2936 5.01898 15.3337 5.20128 15.3337 5.3856C15.3337 5.56991 15.2936 5.75222 15.216 5.92099C15.1384 6.08976 15.0249 6.24131 14.8828 6.36607L9.08542 11.5929C8.79034 11.8546 8.40251 12 7.99978 12C7.59705 12 7.20922 11.8546 6.91414 11.5929L1.11882 6.36607C0.976427 6.24147 0.862727 6.08997 0.784943 5.92118C0.70716 5.75239 0.666992 5.57 0.666992 5.3856C0.666992 5.20119 0.70716 5.01881 0.784943 4.85002C0.862727 4.68123 0.976427 4.52972 1.11882 4.40513C1.41311 4.14346 1.80075 3.99865 2.20289 4.00014Z"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.20289 4.00014C2.60565 3.99688 2.99466 4.13986 3.29115 4.40013L8.00449 8.64699L12.7095 4.40513C13.0056 4.14462 13.3935 4 13.7961 4C14.1988 4 14.5867 4.14462 14.8828 4.40513C15.0249 4.52988 15.1384 4.68144 15.216 4.85021C15.2936 5.01898 15.3337 5.20128 15.3337 5.3856C15.3337 5.56991 15.2936 5.75222 15.216 5.92099C15.1384 6.08976 15.0249 6.24131 14.8828 6.36607L9.08542 11.5929C8.79034 11.8546 8.40251 12 7.99978 12C7.59705 12 7.20922 11.8546 6.91414 11.5929L1.11882 6.36607C0.976427 6.24147 0.862727 6.08997 0.784943 5.92118C0.70716 5.75239 0.666992 5.57 0.666992 5.3856C0.666992 5.20119 0.70716 5.01881 0.784943 4.85002C0.862727 4.68123 0.976427 4.52972 1.11882 4.40513C1.41311 4.14346 1.80075 3.99865 2.20289 4.00014Z"
            fill={color}
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.20289 4.00014C2.60565 3.99688 2.99466 4.13986 3.29115 4.40013L8.00449 8.64699L12.7095 4.40513C13.0056 4.14462 13.3935 4 13.7961 4C14.1988 4 14.5867 4.14462 14.8828 4.40513C15.0249 4.52988 15.1384 4.68144 15.216 4.85021C15.2936 5.01898 15.3337 5.20128 15.3337 5.3856C15.3337 5.56991 15.2936 5.75222 15.216 5.92099C15.1384 6.08976 15.0249 6.24131 14.8828 6.36607L9.08542 11.5929C8.79034 11.8546 8.40251 12 7.99978 12C7.59705 12 7.20922 11.8546 6.91414 11.5929L1.11882 6.36607C0.976427 6.24147 0.862727 6.08997 0.784943 5.92118C0.70716 5.75239 0.666992 5.57 0.666992 5.3856C0.666992 5.20119 0.70716 5.01881 0.784943 4.85002C0.862727 4.68123 0.976427 4.52972 1.11882 4.40513C1.41311 4.14346 1.80075 3.99865 2.20289 4.00014Z"
            fill={color}
            fillOpacity="0.2"
          />
        </g>
      </g>
    </svg>
  );
};

export default ArrowDown;
