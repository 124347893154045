import styled, { css } from 'styled-components';
import Logo from 'assets/img/Logo.png';
import { palette } from 'modules/defines/styles';
const AppBanner = ({ os, browser, isFixed = true, type = 'default' }) => {
  const onClickBanner = () => {
    window.location.href = `https://share.favapp.io/app`;
  };
  return (
    <>
      {browser.isFavApp ? (
        // <AppBannerSpaceWrapper>
        //   <AppBannerSpace />
        // </AppBannerSpaceWrapper>
        <></>
      ) : type === 'float' ? (
        <AppBannerFloatContainer>
          <AppBannerFloatWrapper>
            <AppBannerLogoCell>
              <AppBannerLogo />
              <AppBannerText>핫플 최대 45% 혜택 더보기</AppBannerText>
            </AppBannerLogoCell>
            <AppBannerButton onClick={onClickBanner}>
              앱으로 이동
            </AppBannerButton>
          </AppBannerFloatWrapper>
        </AppBannerFloatContainer>
      ) : (
        <AppBannerContainer isFixed={isFixed}>
          <AppBannerLogoCell>
            <AppBannerLogo />
            <AppBannerText>핫플 최대 45% 혜택 더보기</AppBannerText>
          </AppBannerLogoCell>
          <AppBannerButton onClick={onClickBanner}>앱으로 이동</AppBannerButton>
        </AppBannerContainer>
      )}
    </>
  );
};
const AppBannerFloatContainer = styled.div`
  margin-top: 8px;
  position: absolute;
  z-index: 1000;
  width: 100%;
  display: flex;
  flex: 1;
  max-width: 40rem;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const AppBannerFloatWrapper = styled.div`
  width: 100%;
  background-color: ${palette.neutral008};
  display: flex;
  justify-content: space-between;
  width: calc(100% - 50px);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  @media (max-width: 500px) {
    width: calc(100% - 20px);
  }
`;

const AppBannerContainer = styled.div`
  display: flex;
  flex: 1;
  z-index: 1000;
  height: 3.125rem;
  justify-content: space-between;
  max-width: 40rem;
  width: 100%;
  align-items: center;
  background-color: ${palette.neutral008};
  color: white;
  position: ${(props) => (props.isFixed ? 'fixed' : 'relative')};
  top: 0;
`;
const AppBannerLogoCell = styled.div`
  display: flex;
  align-items: center;
`;
const AppBannerLogo = styled.div`
  margin-left: 20px;
  margin-right: 10px;
  width: 2rem;
  height: 2rem;

  background-image: url(${Logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
const AppBannerText = styled.div`
  align-items: center;

  color: ${palette.fontWhite};
  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const AppBannerButton = styled.button`
  padding: 10px;
  cursor: pointer;
  text-align: center;
  margin-right: 20px;

  color: ${palette.primary};
  font-feature-settings:
    'liga' off,
    'clig' off;

  /* Button */
  font-family: Pretendard;
  font-size: 1.063rem;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 117.647% */
  &:hover {
    color: ${palette.primaryHover};
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AppBannerSpace = styled.div`
  display: flex;
  flex: 1;
  z-index: 1000;
  height: 3.125rem;
  justify-content: center;
  max-width: 40rem;
  width: 100%;
  align-items: center;
  background-color: ${palette.white};
  position: fixed;
`;
const AppBannerSpaceWrapper = styled.div`
  position: relative;
  height: 3.125rem;
  max-width: 40rem;
  width: 100%;
`;

export default AppBanner;
