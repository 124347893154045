import { icon_chevron_up } from 'assets/icon';
import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
const ScrollTopButton = () => {
  const [right, setRight] = useState(0);
  const [show, setShow] = useState(false);
  useEffect(() => {
    const updateRight = () => {
      const viewportWidth = window.innerWidth;
      const parentMaxWidth = 40 * 16; // 40rem을 px로 변환
      const right = Math.max((viewportWidth - parentMaxWidth) / 2, 0);
      setRight(right);
    };

    window.addEventListener('resize', updateRight);
    updateRight();

    return () => window.removeEventListener('resize', updateRight);
  }, []);

  useEffect(() => {
    const scrollFunction = () => {
      if (window.scrollY > 300) {
        setShow(true);
      } else {
        setShow(false);
      }
    };
    window.addEventListener('scroll', scrollFunction);
    return () => {
      window.removeEventListener('scroll', scrollFunction);
    };
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {show ? (
        <Button
          right={right}
          onClick={scrollTop}
          type="button"
          className="scroll-top-button"
        >
          <Icon className="fas fa-arrow-up">
            <img src={icon_chevron_up} alt="scroll-top" />
          </Icon>
        </Button>
      ) : null}
    </>
  );
};

const FadeIn = keyframes`
    from {
    opacity: 0;
    transform: translateY(20%);
    }
    to {
    opacity: 1;
    transform: translateY(0);
    }
`;

const Button = styled.button<{ right: number }>`
  animation: ${FadeIn} 0.5s;
  position: fixed;
  right: ${(props) => props.right + 20}px;
  bottom: 110px;
  z-index: 99999;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: white;
  filter: drop-shadow(0px 5px 20px rgba(66, 66, 66, 0.4));
`;

const Icon = styled.i`
  padding: 10px;
`;
export default ScrollTopButton;
