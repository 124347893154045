export const pagePaths = {
  main: '/',
  space: '/space/:sid',
  //

  product: '/product/:pid',
  purchase: '/purchase',
  payment: '/payment',
  payment_success: '/payment/success',
  payment_fail: '/payment/fail',
  billing: '/billing',
  billing_success: '/billing/success',
  billing_fail: '/billing/fail',
  //
  purchase_list: '/purchase-list',
  purchase_detail: '/purchase/:oid',
  purchase_ticket: '/purchase-ticket/:oid',
};

export const apiPaths = {
  // awsBaseUrl: "https://api.pkbroz.com/",
  awsBaseUrl: 'https://apidev.pkbroz.com/store',
  devBaseUrl: 'http://localhost:5030/store',

  getProductList: '/product/list',
  getProductOne: '/product/',
};

export const externalUrls = {
  termsOfService: 'https://favapp.io/docs/terms-of-service',
  privacyPolicy: 'https://favapp.io/docs/privacy-policy',
};
