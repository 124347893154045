import React from 'react';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import styled from 'styled-components';
import { pagePaths } from 'modules/defines/paths';
import MainContainer from 'containers/MainContainer';
import ProductDetailContainer from 'containers/ProductDetailContainer';
import PurchaseContainer from 'containers/PurchaseContainer';
import PaymentWidget from 'containers/payment/PaymentWidget';
import PaymentSuccess from 'containers/payment/PaymentSuccess';
import PaymentFail from 'containers/payment/PaymentFail';
import PurchaseListContainer from 'containers/PurchaseListContainer';
import PurchaseDetailContainer from 'containers/PurchaseDetailContainer';
import PurchaseTicketContainer from 'containers/PurchaseTicketContainer';
import SpaceContainer from 'containers/SpaceContainer';

const router = createBrowserRouter([
  {
    path: pagePaths.main,
    element: <MainContainer />,
  },
  {
    path: pagePaths.space,
    element: <SpaceContainer />,
  },
  {
    path: pagePaths.product,
    element: <ProductDetailContainer />,
  },
  {
    path: pagePaths.purchase,
    element: <PurchaseContainer />,
  },
  // 결제 관련
  {
    path: pagePaths.payment,
    element: <PaymentWidget />,
  },
  {
    path: pagePaths.payment_success,
    element: <PaymentSuccess />,
  },
  {
    path: pagePaths.payment_fail,
    element: <PaymentFail />,
  },
  // 구매 내역 페이지
  {
    path: pagePaths.purchase_list,
    element: <PurchaseListContainer />,
  },
  {
    path: pagePaths.purchase_detail,
    element: <PurchaseDetailContainer />,
  },
  {
    path: pagePaths.purchase_ticket,
    element: <PurchaseTicketContainer />,
  },
  // 장바구니
  {
    path: '*',
    element: <Navigate to={pagePaths.main} />,
  },
]);

const RouterComponent = () => (
  <ContentWrapper>
    <RouterProvider router={router} />
  </ContentWrapper>
);

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 0px;
  position: relative;
`;

export default RouterComponent;
