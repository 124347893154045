import styled from 'styled-components';

import bg_img from 'assets/img/bg.webp';
import { palette } from 'modules/defines/styles';

const StoreBanner = ({
  isFixed = true,
  marginTop = '0',
  marginBottom = '0',
}) => {
  const onClickBanner = () => {
    window.location.href = `https://tally.so/r/3qBox9`;
  };
  return (
    <AppBannerContainer
      isFixed={isFixed}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <AppBannerText>줄 서는 공간의 베스트 상품을 소개해드려요!</AppBannerText>
      <AppBannerButton onClick={onClickBanner}>입점문의</AppBannerButton>
    </AppBannerContainer>
  );
};
const AppBannerContainer = styled.div`
  display: flex;
  z-index: 1000;
  justify-content: space-between;
  width: 100%;
  max-width: 40rem;
  align-items: center;
  color: white;
  position: ${(props) => (props.isFixed ? 'fixed' : 'relative')};
  top: 0;
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  height: 2.5rem;
  background: url(${bg_img});
`;
const AppBannerText = styled.div`
  align-items: center;

  color: ${palette.fontWhite};
  font-feature-settings:
    'liga' off,
    'clig' off;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.26px;
  margin-left: 5.625rem;
  @media screen and (max-width: 620px) {
    margin-left: 1.875rem;
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const AppBannerButton = styled.button`
  padding: 8px;
  cursor: pointer;
  text-align: center;

  color: ${palette.white};
  font-feature-settings:
    'liga' off,
    'clig' off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.28px;
  &:hover {
    color: ${palette.neutral003};
  }
  margin-right: 4.375rem;
  @media screen and (max-width: 620px) {
    margin-right: 1.875rem;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default StoreBanner;
