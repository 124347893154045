// UTF-8 문자열을 Base64로 인코딩
export function encodeToBase64(str: string) {
  const utf8Bytes = new TextEncoder().encode(str);
  let binaryString = '';
  for (let i = 0; i < utf8Bytes.length; i++) {
    binaryString += String.fromCharCode(utf8Bytes[i]);
  }
  let base64String = btoa(binaryString);
  // URL-safe Base64
  base64String = base64String
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
  return encodeURIComponent(base64String);
}

// Base64를 UTF-8 문자열로 디코딩
export function decodeFromBase64(base64: any) {
  const decodedBase64 = decodeURIComponent(base64);

  var standardBase64 = decodedBase64.replace(/-/g, '+').replace(/_/g, '/');
  const pad = standardBase64.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error(
        'InvalidLength: Input base64url string is the wrong length to be decoded.'
      );
    }
    standardBase64 += new Array(5 - pad).join('=');
  }

  const binaryString = atob(standardBase64);
  const utf8Bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    utf8Bytes[i] = binaryString.charCodeAt(i);
  }

  const decoded = new TextDecoder().decode(utf8Bytes);
  return decoded;
}
